import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";

import { Layout, Alert, Collapse, Form, AutoComplete, Input, Row, Col, Select, Checkbox, Button, message, Radio } from "antd";

import Navbar from '../../../atoms/navbar';

import './styles.css';
import API from '../../../services';
import HeaderFarms from '../../../utils/header_farms';

import { loginRequest } from '../../../config/api-config';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Block, Confirm, Loading, Report } from 'notiflix';

function CreateServiceRequest() {

    const [form] = Form.useForm();

    const { Panel } = Collapse;
    const { TextArea } = Input;

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    //auto complete username 
    const [options, setOptions] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [applications, setApplications] = useState([]);

    const [typeRequestExplain, setTypeRequestExplain] = useState(true);
    const [justification, setJustification] = useState(true)
    const [EquipmentDetails,] = useState(true);

    const [equipmentDetails, setEquipmentDetails] = useState(false)
    const [networkFolder, setNetworkFolder] = useState(false)
    const [permission, setPermission] = useState(false)
    const [, setCommunicationAccess] = useState(false)
    const [, setDescription] = useState(false)
    const [, setAccountRole] = useState(false)

    const [hiddenLoginName, setHiddenLoginName] = useState(true)
    const [currentUser, setCurrentUser] = useState(null)
    const [loginNameValid, setLoginNameValid] = useState({
        validateStatus: null,
        help: null
    })

    const [otherChecked, setOtherChecked] = useState(false);
    const [otherSystem, setOtherSystem] = useState('');

    const [otherAccountChecked, setOtherAccountChecked] = useState(false);
    const [otherAccount, setOtherAccount] = useState('');

    const [employeeStatus, setEmployeeStatus] = useState(null);

    const handleOtherAccountChange = (e) => {
        setOtherAccount(e.target.value);
    };

    const handleOtherCheckboxChange = (e) => {
        setOtherChecked(e.target.checked);
    };

    const handleOtherSystemChange = (e) => {
        setOtherSystem(e.target.value);
    };


    const changeTypeRequest = () => {
        form.setFieldsValue({
            equipment_details: [],
            equipment_details_explain: null,
            account_request: null,
            account_request_other: null,
            system_request: [],
            system_request_other: null,
            justification: null,
            login_name: null,
            network_folder: null,
            permission_network_folder: [],
            communication_access: [],
            description: null,
        });
    };

    let navigate = useNavigate();

    const onSelectName = async (value) => {
        const selectedOption = options.find(option => option.value === value);
        const detailData = selectedOption.detail;

        form.setFieldsValue({
            position: detailData.employment.job_position,
            department: detailData.employment.organization_name,
            phone_number: detailData.personal.mobile_phone,
            company: detailData.employment.branch,
            nik: detailData.employment.employee_id,
            employee_status: detailData.employment.employment_status
        });
    };

    const changeEmployeeStatus = (event) => {
        setEmployeeStatus(event.target.value)

        form.setFieldsValue({
            fullname: null,
            position: null,
            department: null,
            phone_number: null,
            company: null,
            nik: null,
            employee_status: "Non Employee"
        });
    }

    const getDepartment = async (token) => {
        try {
            const res = await API.getDepartment(HeaderFarms(token), null);

            let DTO = [];

            res.data.forEach(i => {
                DTO.push({
                    id: i._id,
                    name: i.fullname
                });
            });

            setDepartments(DTO);
        } catch (err) {
            console.log(err);
            message.warning('Terjadi Kesalahan saat load data department');
        }
    }

    const getApplications = async (token) => {
        try {
            const res = await API.getApplications(HeaderFarms(token), null);

            let DTO = [];

            res.forEach(i => {
                DTO.push({
                    id: i.app_id,
                    name: i.name
                });
            });

            setApplications(DTO);
        } catch (err) {
            console.log(err);
            message.warning('Terjadi Kesalahan saat load data application');
        }
    }

    const AILoginName = async (data_user) => {

        try {

            let login_name_result;

            let splitFullName = data_user.fullname.toLowerCase().trim().split(' ');
            let getDomain = (data_user.company.domain ? '@' + data_user.company.domain : null).trim()
            let getLastName = data_user.fullname.trim().split(" ");

            let sendApi = [];
            let firstNameStack = '';

            splitFullName[0].toLowerCase().split('').forEach(async i => {
                firstNameStack += i
                sendApi.push(firstNameStack + getLastName.pop().toLowerCase() + getDomain)
            })

            let response = await getUser365toMicrosoftGraph(sendApi[0])

            if (response.value.length) {
                login_name_result = sendApi[sendApi.indexOf(response.value[0].userPrincipalName) + 1]
                return login_name_result
            }

            login_name_result = sendApi[0]

            return login_name_result
        } catch (error) {
            console.error(error)
            message.warning('Terjadi Kesalahan saat AI Login Name')
        }
    }

    const chackCustomLoginName = async (e) => {

        setLoginNameValid({
            validateStatus: 'validating',
            help: "The login name is being validated..."
        })

        try {
            let response = await getUser365toMicrosoftGraph(e.target.value)

            if (response.value.length > 0)
                setLoginNameValid({
                    validateStatus: 'error',
                    help: "Login name already used"
                })
            else
                setLoginNameValid({
                    validateStatus: 'success',
                    help: "Login name can be used"
                })

        } catch (error) {
            console.error(error)
            message.warning('Terjadi Kesalahan saat check login name')
        }
    }

    const getUser365toMicrosoftGraph = async (filterName) => {
        try {
            const request = {
                ...loginRequest,
                account: accounts[0],
            };

            const getToken = await instance.acquireTokenSilent(request);

            const response = await fetch(`https://graph.microsoft.com/v1.0/users?$select=userPrincipalName&$filter=startswith(userPrincipalName,'${filterName}')`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken.accessToken}`,
                },
            });

            return response.json();

        } catch (error) {
            console.error(error)
            message.warning('Terjadi Kesalahan saat validasi username 365')
        }
    }

    const createServiceRequest = (data, token) => {
        API.newServiceRequest(HeaderFarms(token), data).then(res => {
            Report.success(
                `The request was successfully`,
                "Thankyou, We will immediately process your request",
                "Okay",
                function () {
                    navigate('/sr/history')
                }
            );
            Loading.remove();
        }).catch(err => {
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        })
    }

    const RequestToken = async () => {
        try {
            const request = {
                ...loginRequest,
                account: accounts[0],
            };

            const responseToken = await instance.acquireTokenSilent(request);
            await getUsersBasedOnBranch();
            await getApplications(responseToken.accessToken)
            await getDepartment(responseToken.accessToken)

        } catch (e) {
            handleLogin(instance);
        }
    }

    const onFinish = async (values) => {

        let response = await getUser365toMicrosoftGraph(values.login_name)

        if (response.value.length > 0) {
            message.warning('Login name sudah tersedia.')
            return false
        }

        Confirm.show(
            'Important Confirmation',
            'Are you sure ? Please make sure your request is correct.',
            'Yes',
            'No',
            () => {

                let result = values
                result.fullname = result.fullname.split('-')[0]

                result.equipment_details = result.equipment_details.join(' | ')

                console.log(result)

                Object.keys(result).forEach(key => {
                    if (result[key] === undefined) {
                        result[key] = null;
                    }
                });

                const request = {
                    ...loginRequest,
                    account: accounts[0],
                };

                instance
                    .acquireTokenSilent(request)
                    .then((responseToken) => {
                        createServiceRequest(result, responseToken.accessToken)
                    })
                    .catch((e) => {
                        navigate("/");
                    });
            },
        );
    };

    const onChangeCollapse = (key) => {
    };

    const handlePhoneNumberChange = (e) => {
        let value = e.target.value;

        if (value === '0') {
            value = '62';
        }

        // Remove any non-numeric characters
        value = value.replace(/\D/g, '');

        form.setFieldsValue({
            phone_number: value,
        });
    };

    const typeRequestHandler = (e) => {

        if (e) {
            clearMandatory()


            if (e === 'Other') {
                setTypeRequestExplain(false)
            } else {
                setTypeRequestExplain(true)
            }

            if (e === 'New Request') {
                newRequestMandatory()
            }

            if (e === 'Equipment Replacement') {
                EquipmentReplacement()
            }

            if (e === 'Change / Modify') {
                ChangeModify()
            }

            changeTypeRequest()
            setHiddenLoginName(true)
            setJustification(true)
            setOtherChecked(false)
            setOtherAccountChecked(false)
        }
    }

    const clearMandatory = () => {
        setCommunicationAccess(false)
        setEquipmentDetails(false)
        setNetworkFolder(false)
        setAccountRole(false)
        setPermission(false)
        setDescription(false)
    }

    const EquipmentReplacement = () => {
        setDescription(true)
    }

    const ChangeModify = () => {
        setDescription(true)
    }

    const newRequestMandatory = () => {
        setAccountRole(true)
        setCommunicationAccess(true)
    }

    const JusticationCheck = (FormValues) => {
        // if checked email to be sure need internet access
        if (FormValues.account_request.includes('Internet Access')
            || FormValues.account_request.includes('Email')) {
            setJustification(false)
        } else {
            setJustification(true)
        }
    }

    const AutoChecklist = async (FormValues, event) => {
        try {
            if (event.target.value === "Email") {
                if (FormValues.account_request.includes('Email')) {
                    form.setFieldsValue({
                        account_request: ['Email', 'Internet Access', 'AD / FARMS'],
                        login_name: await AILoginName({
                            fullname: FormValues.fullname,
                            company: { domain: currentUser.email.split('@')[1] }
                        })
                    });
                }
            }
        } catch (error) {
            message.warning('Error in AutoChecklist:', error)
        }

        JusticationCheck(FormValues);
    }


    const AccountRequestHandler = async (event) => {

        const FormValues = form.getFieldValue();

        const hasOtherAccountRequest = FormValues.account_request.includes('Other');
        setOtherAccountChecked(hasOtherAccountRequest);

        const requiresLoginName = FormValues.account_request.includes('Email')
            || FormValues.account_request.includes('AD / FARMS');

        setHiddenLoginName(!requiresLoginName);

        JusticationCheck(FormValues)

        if (!requiresLoginName) {
            form.setFieldsValue({ login_name: null });
        }

        AutoChecklist(FormValues, event)
    }

    const PermissionNetworkFolderHandler = (event) => {
        if (event.includes('Full Access')) {
            form.setFieldsValue({
                permission_network_folder: ['Full Access', 'Modifiy / Write', 'Read Only'],
            })
        }
        else {
            form.setFieldsValue({
                permission_network_folder: event
            })
        }
    }

    const InformationNoted = () => {
        return (
            <>
                <ul className='note-sr'>
                    <li>By submitting this form, the user agrees to be responsible for the security, operation, and cost of the requested service(s)</li>
                    <li>IT  Div. will review the justification and technical aspects of the request.  IT  Div. may decline the request, and will advise the requestor</li>
                    <li>All details must be completed in full or request will not be approved.</li>
                    <li>Must specify the expiry date for non/Temporary employee</li>
                </ul>
            </>
        )
    }

    async function getUsersBasedOnBranch() {
        try {

            Block.arrows('.loading', "Mengambil data dari Talenta..");

            setCurrentUser(JSON.parse(localStorage.getItem('session_signin')))

            let getOptionsNameByBranch = await getEmployeeTalentaByBranch("48276,48274,48275,48277,55811,0")

            let filter = [];

            getOptionsNameByBranch.forEach(employee => {
                filter.push({
                    value: employee.personal.first_name + " " + employee.personal.last_name,
                    label: employee.personal.first_name + " " + employee.personal.last_name,
                    key: employee.personal.barcode,
                    detail: employee,
                });
            })

            setOptions(filter);

            Block.remove('.loading');
        } catch (error) {
            console.error(error)
        }
    }

    const getEmployeeTalentaByBranch = async (id) => {
        try {
            const { data: employees } = await API.getEmployeeTalentaByBranch(HeaderFarms(null), id);

            return employees
        } catch (error) {

            console.error("Error in getEmployeeTalentaByBranch:", error);
        }
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then(() => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    useEffect(() => {
        // Check already login or not ? 
        if (!isAuthenticated) {
            RequestToken()
        } else {
            if (instance.getAllAccounts().length > 0) {
                instance.setActiveAccount(accounts[0]);
                RequestToken()
            } else {
                handleLogin(instance)
            }
        }

    }, [isAuthenticated])

    return (
        <>
            <Navbar
                title="Create"
                back={true}
                subtitle="Service Request"
                navigate="/sr/history"
            ></Navbar>
            <div className="container-fluid white-back-cr">
                <Form
                    form={form}
                    name="service_request_form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={() => message.warning('Mohon lengkapi formulir.')}
                    autoComplete="off"
                    size='small'
                    layout='vertical'
                >
                    <div className="container p-3" style={{ alignSelf: "center" }}>
                        <div className="row">
                            <div className='col-md-12'>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']} onChange={onChangeCollapse}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>User Requiring Access</b>} key="1">
                                        <Row gutter={10} className="mb-1">
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Status Employee"
                                                    name={'employee_status_first'}
                                                    rules={[{ required: true, message: 'Please input Status' }]}
                                                >
                                                    <Radio.Group
                                                        onChange={changeEmployeeStatus}
                                                    >
                                                        <Radio value="Employee">Employee</Radio>
                                                        <Radio value="Contractor">Non Employee</Radio>
                                                    </Radio.Group>
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={10} className="mb-1">
                                            {
                                                employeeStatus === 'Employee' ? (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>

                                                        <Form.Item
                                                            className='loading'
                                                            label="Fullname"
                                                            name="fullname"
                                                            rules={[{ required: true, message: 'Please input fullname' }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                size="middle"
                                                                placeholder="Pilih nama"
                                                                optionFilterProp="children"
                                                                onSelect={onSelectName}
                                                                filterOption={(inputValue, option) =>
                                                                    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                                                }
                                                            >
                                                                {options.map(option => (
                                                                    <Select.Option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>

                                                        </Form.Item>
                                                    </Col>
                                                ) : (
                                                    <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                        <Form.Item
                                                            className='loading'
                                                            label="Fullname"
                                                            name="fullname"
                                                            rules={[{ required: true, message: 'Please input fullname' }]}
                                                        >
                                                            <Input placeholder="Rinaldi Kautsar" />
                                                        </Form.Item>
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Position"
                                                    name="position"
                                                    rules={[{ required: true, message: 'Please input Position' }]}
                                                >
                                                    <Input placeholder="Staff - IT Support" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>

                                                <Form.Item
                                                    label="Department"
                                                    name="department"
                                                    rules={[{ required: false, message: 'Please input Department' }]}
                                                >
                                                    <AutoComplete
                                                        placeholder="Select Department"
                                                        filterOption={(inputValue, option) =>
                                                            option.value.toLowerCase().includes(inputValue.toLowerCase())
                                                        }
                                                    >
                                                        {departments.map(department => (
                                                            <AutoComplete.Option key={department.id} value={department.name}>
                                                                {department.name}
                                                            </AutoComplete.Option>
                                                        ))}
                                                    </AutoComplete>

                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Employee ID"
                                                    name="nik"
                                                    rules={[{ required: true, message: 'Please input Employee ID' }]}
                                                >
                                                    <Input
                                                        placeholder="Employee ID /  NIK"
                                                        onKeyPress={(e) => {
                                                            if (e.which < 48 || e.which > 57) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 8 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Business Unit"
                                                    name="company"
                                                    rules={[{ required: true, message: 'Please select business unit' }]}
                                                >
                                                    <Select placeholder="Select Business Unit" readOnly>
                                                        <Select.Option value="Bumi Resources Minerals">Bumi Resources Minerals</Select.Option>
                                                        <Select.Option value="Citra Palu Minerals">Citra Palu Minerals</Select.Option>
                                                        <Select.Option value="Gorontalo Minerals">Gorontalo Minerals</Select.Option>
                                                        <Select.Option value="Linge Mineral Resources">Linge Mineral Resources</Select.Option>
                                                        <Select.Option value="Dairi Prima Minerals">Dairi Prima Minerals</Select.Option>
                                                    </Select>
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Phone"
                                                    name="phone_number"
                                                    rules={[{ required: false, message: 'Please input phone' }]}
                                                >
                                                    <Input
                                                        placeholder="example: 628597721233"
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        onChange={handlePhoneNumberChange}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Employee Status"
                                                    name="employee_status"
                                                    rules={[{ required: true, message: 'Please input status' }]}
                                                >
                                                    <Select>
                                                        <Select.Option value="Contract">Contract</Select.Option>
                                                        <Select.Option value="Permanent">Permanent</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Equipment Request</b>} key="2">
                                        <Row className='mt-3' gutter={20}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Type of Request"
                                                    name="type_request"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input type request',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        placeholder="Select"
                                                        onChange={typeRequestHandler}
                                                        options={[
                                                            {
                                                                value: 'New Request',
                                                                label: 'New Request',
                                                            },
                                                            {
                                                                value: 'Equipment Replacement',
                                                                label: 'Equipment Replacement',
                                                            },
                                                            {
                                                                value: 'Change / Modify',
                                                                label: 'Change / Modify',
                                                            },
                                                            {
                                                                value: 'Other',
                                                                label: 'Other, please explain',
                                                            },
                                                        ]}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={
                                                        <span>
                                                            Type Request&nbsp;
                                                            <em style={{ fontStyle: 'italic', fontSize: 10 }}>( Please explain )</em>
                                                        </span>
                                                    }
                                                    name="type_request_explain"
                                                    hidden={typeRequestExplain}
                                                    rules={[
                                                        {
                                                            required: !typeRequestExplain,
                                                            message: 'Please input type explain',
                                                        },
                                                    ]}
                                                >
                                                    <TextArea rows={2} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Equipment"
                                                    name="equipment_details"
                                                    rules={[
                                                        {
                                                            required: equipmentDetails,
                                                            message: 'Please input Equipment Details',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        size='middle'
                                                        placeholder="Select"
                                                        allowClear={true}
                                                        mode="multiple"
                                                        options={[
                                                            {
                                                                value: 'Notebook General Spec',
                                                                label: 'Notebook General Spec',
                                                            },
                                                            {
                                                                value: 'Notebook Engineering',
                                                                label: 'Notebook Engineering',
                                                            },
                                                            {
                                                                value: 'Desktop General Spec',
                                                                label: 'Desktop General Spec',
                                                            },
                                                            {
                                                                value: 'Desktop Engineering',
                                                                label: 'Desktop Engineering',
                                                            },
                                                            {
                                                                value: 'Radio (HT/RIG)',
                                                                label: 'Radio (HT/RIG)',
                                                            },
                                                        ]}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label={
                                                        <span>
                                                            Equipment Other&nbsp;
                                                            <em style={{ fontStyle: 'italic', fontSize: 10 }}>( Please explain )</em>
                                                        </span>
                                                    }
                                                    name="equipment_details_explain"
                                                    hidden={!EquipmentDetails}
                                                    rules={[
                                                        {
                                                            required: !EquipmentDetails,
                                                            message: 'Please input type explain',
                                                        },
                                                    ]}
                                                >
                                                    <TextArea rows={2} />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                    </Panel>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>System Request</b>} key="3">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    className="mt-4"
                                                    label={<b>Account</b>}
                                                    name="account_request"
                                                    rules={[
                                                        {
                                                            // required: accountRole,
                                                            required: false,
                                                            message: 'Please input account',
                                                        },
                                                    ]}
                                                >
                                                    <Checkbox.Group>
                                                        <Row>
                                                            <Col onChange={AccountRequestHandler} span={12}>
                                                                <Checkbox value="Email">Email</Checkbox>
                                                            </Col>
                                                            <Col onChange={AccountRequestHandler} span={12}>
                                                                <Checkbox value="AD / FARMS">AD / FARMS</Checkbox>
                                                            </Col>
                                                            <Col onChange={AccountRequestHandler} span={12}>
                                                                <Checkbox value="Internet Access">Internet Access</Checkbox>
                                                            </Col>
                                                            <Col onChange={AccountRequestHandler} span={12}>
                                                                <Checkbox value="PLC">PLC</Checkbox>
                                                            </Col>
                                                            <Col onChange={AccountRequestHandler} span={12}>
                                                                <Checkbox value="Epicor Account">Epicor Account</Checkbox>
                                                            </Col>
                                                            <Col onChange={AccountRequestHandler} span={12}>
                                                                <Checkbox value="CCTV">CCTV</Checkbox>
                                                            </Col>
                                                            <Col onChange={AccountRequestHandler} span={12}>
                                                                <Checkbox value="VPN">VPN</Checkbox>
                                                            </Col>
                                                            <Col onChange={AccountRequestHandler} span={12}>
                                                                <Checkbox value="Other">Other</Checkbox>
                                                                {otherAccountChecked && (
                                                                    <Form.Item
                                                                        label={<em style={{ fontSize: 10, marginTop: 10 }}>(Please Explain)</em>}
                                                                        name="account_request_other"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input account',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <TextArea
                                                                            rows={2}
                                                                            placeholder="Please input other account"
                                                                            value={otherAccount}
                                                                            onChange={handleOtherAccountChange}
                                                                        />
                                                                    </Form.Item>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    className="mt-4"
                                                    label={<b>System</b>}
                                                    name="system_request"
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: 'Please input system',
                                                        },
                                                    ]}
                                                >
                                                    <Checkbox.Group>
                                                        <Row>
                                                            {applications.map((item) => (
                                                                <Col span={12} key={item.id}>
                                                                    <Checkbox value={item.id}>{item.name}</Checkbox>
                                                                </Col>
                                                            ))}
                                                            <Col span={12}>
                                                                <Checkbox value={'Other'} onChange={handleOtherCheckboxChange}>
                                                                    Other
                                                                </Checkbox>
                                                                {otherChecked && (
                                                                    <Form.Item
                                                                        label={<em style={{ fontSize: 10, marginTop: 10 }}>(Please Explain)</em>}
                                                                        name="system_request_other"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'Please input system',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <TextArea
                                                                            rows={2}
                                                                            placeholder="Please input other system"
                                                                            value={otherSystem}
                                                                            onChange={handleOtherSystemChange}
                                                                        />
                                                                    </Form.Item>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3' gutter={10}>
                                            <Col xs={{ span: 23 }} span={12}>
                                                <Form.Item
                                                    label={<><span style={{ fontSize: 11 }}>Justication ( Why internet acces required and/or why additional new access point is required ?  ) </span></>}
                                                    name="justification"
                                                    hidden={justification}
                                                    rules={[
                                                        {
                                                            required: !justification,
                                                            message: 'Please input type explain',
                                                        }
                                                    ]}
                                                >
                                                    <TextArea rows={4} className="" />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                    </Panel>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Network Access and Communication Request</b>} key="4">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    hasFeedback
                                                    label="Login Name"
                                                    name="login_name"
                                                    help={loginNameValid.help}
                                                    hidden={hiddenLoginName}
                                                    validateStatus={loginNameValid.validateStatus}
                                                    rules={[{ required: false, message: 'Please input login name' }]}
                                                >
                                                    <Input readOnly onChange={chackCustomLoginName} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20} className="mt-3">
                                            <Col xs={{ span: 23 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Network Folder / File Path"
                                                    name="network_folder"
                                                    rules={[{ required: networkFolder, message: 'Please input network folder' }]}
                                                >
                                                    <Input placeholder='Example : HSE or Legal' />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 23 }} sm={{ span: 10 }} xl={{ span: 10 }}>
                                                <Form.Item
                                                    label="Permissions"
                                                    name="permission_network_folder"
                                                    rules={[{ required: permission, message: 'Please input network folder' }]}
                                                >
                                                    <Checkbox.Group
                                                        onChange={PermissionNetworkFolderHandler}

                                                    >
                                                        <Row>
                                                            <Col span={12}>
                                                                <Checkbox value="Full Access">Full Access</Checkbox>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Checkbox value="Modifiy / Write">Modifiy /Write</Checkbox>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Checkbox value="Read Only">Read Only</Checkbox>
                                                            </Col>
                                                        </Row>
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    label="Communication Access"
                                                    name="communication_access"
                                                    rules={[{ required: false, message: 'Please input communication access' }]}
                                                >
                                                    <Checkbox.Group
                                                        onChange={null}

                                                    >
                                                        <Row>
                                                            <Col span={12}>
                                                                <Checkbox value="Extension Phone">Extension Phone</Checkbox>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Checkbox value="GSM">GSM</Checkbox>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Checkbox value="Internet Data Package">Internet Data Package</Checkbox>
                                                            </Col>
                                                        </Row>
                                                    </Checkbox.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Description</b>} key="5">
                                        <Row gutter={10}>
                                            <Col xs={{ span: 23 }} span={12}>
                                                <Form.Item
                                                    label={<><span>Description ( Brief description of the requirements & justification )</span></>}
                                                    name="description"
                                                    rules={[
                                                        {
                                                            // required: description,
                                                            required: true,
                                                            message: 'Please input Brief description of the requirements & justification',
                                                        },
                                                    ]}
                                                >

                                                    <TextArea rows={4} className="mt-3" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-12'>
                                <Alert
                                    message="Informational Notes"
                                    description={<InformationNoted />}
                                    type="info"
                                    showIcon
                                    className='note-sr'
                                />
                            </div>
                        </div>
                    </div>
                    <Row className='justify-content-center'>
                        <Col>
                            <Form.Item>
                                <Button type="primary" size="middle" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div >
            <Layout.Footer style={{ textAlign: 'center', background: "#F9F9F9" }}>©2023 Information Technology</Layout.Footer>
        </>
    );
}
export default CreateServiceRequest