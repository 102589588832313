import React, { useEffect, useState } from "react";
import { PageHeader } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import "./styles.css";
import { Confirm } from "notiflix";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { loginRequest } from "../../config/api-config";
import { callMsGraph } from "../../utils/graph";
import { initializeHeaderInfo } from "../../redux/action";
import { connect } from "react-redux";

function Navbar(props) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  let navigate = useNavigate();

  function Logout() {
    Confirm.show(
      "Logout",
      "Are you sure want Logout Session?",
      "Yes",
      "No",
      () => {
        handleLogout(instance)
      },
      {}
    );
  }

  function RequestToken() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    instance.acquireTokenSilent(request).then((response) => {
      callMsGraph(response.accessToken).then(response_graph => {
        props.setSession(response_graph)
      })
    }).catch((e) => {
      handleLogin(instance)
    });
  }

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).then((res) => { })
      .catch(e => {
        console.error(e);
      });
  }

  function handleLogout(instance) {
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
  }

  useEffect(() => {
    if (isAuthenticated) {
      let getSession = props.session ? props.session : {};
      if (Object.keys(getSession).length === 0 &&
        Object.getPrototypeOf(getSession) === Object.prototype) {
        RequestToken();
      }
    }

    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [isAuthenticated, props.session]);

  return (
    <nav className="navbar navbar-light bg-light">
      <div className="container-fluid">
        <PageHeader
          className="site-page-header"
          onBack={() => navigate(`${props.navigate}`)}
          title={props.title}
          subTitle={props.subtitle}
          backIcon={props.back ? <ArrowLeftOutlined /> : ''}
        />
        <div className="uinfo" style={{ cursor: "pointer", display: 'flex', alignItems: 'center' }} onClick={() => Logout()}>
          <div style={{ width: '15px', height: '15px', borderRadius: '20%', backgroundColor: isOnline ? 'green' : 'red', marginRight: '30px' }}></div>
          <div>
            {props.session && props.session.displayName}<br />
            {props.session && props.session.userPrincipalName}
          </div>
        </div>
      </div>
    </nav>
  );
}

const reduxState = (state) => ({
  session: state.session_info,
});

const reduxDispatch = (dispatch) => ({
  setSession: (data) =>
    dispatch(initializeHeaderInfo(data)),
});

export default connect(reduxState, reduxDispatch)(Navbar);
