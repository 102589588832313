import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { Modal, Button, Form, Input, DatePicker, Menu, Select, Tag, Alert, Avatar, Spin, Row, Col, } from 'antd';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { Confirm, Loading, Report } from 'notiflix';
import "react-big-calendar/lib/css/react-big-calendar.css";
import HeaderFarms from '../../utils/header_farms';
import API from '../../services';
import moment from 'moment';
import "./style.css";

import { CheckCircleOutlined, DownOutlined } from '@ant-design/icons';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from '../../config/api-config';

import { useLocation } from 'react-router-dom';
import { useParams } from "react-router";


const localizer = momentLocalizer(moment);
const { Option } = Select;
const { RangePicker } = DatePicker;


function ScheduleDriver() {

    const [modalDetailBookingVisible, setModalDetailBookingVisible] = useState(false);
    const [approvedModalVisible, setApprovedModalVisible] = useState(false);
    const [bookingModalVisible, setBookingModalVisible] = useState(false);
    const [rejectModalVisible, setRejectModalVisible] = useState(false);

    const [selectedNumberPlate, setSelectedNumberPlate] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [defaultDate, setDefaultDate] = useState(null)
    const [targetNode, setTargetNode] = useState(null);
    const [listBooking, setListBooking] = useState([]);
    const [listDriver, setListDriver] = useState([])

    const [loading, setLoading] = useState(true);
    const [loadingCalendar, setLoadingCalendar] = useState(false);

    const [plateNumber,] = useState([
        { key: "B-2694-SRX", type: "Genap" },
        { key: "B-2799-SRY", type: "Ganjil" }
    ]);

    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    let { id: id_record } = useParams();
    const location = useLocation();
    const [form] = Form.useForm();

    const isOddDay = currentDate.getDate() % 2 !== 0;

    const handleSelectEventAndOpenModal = (event) => {
        setSelectedEvent(event);
        setModalDetailBookingVisible(true);
    };

    const closeModalDetailBooking = () => {
        setModalDetailBookingVisible(false);
    };

    const handleNavigateDate = async (date) => {
        setCurrentDate(date);
    };

    const openBookingModal = () => {
        if (selectedNumberPlate)
            setBookingModalVisible(true);
        else
            Report.info(
                "Select Vehicle",
                "Please select a vehicle from the list below.",
                "Okay"
            );
    };

    const closeBookingModal = () => {
        setBookingModalVisible(false)
    }

    const openApproveModal = () => {
        setApprovedModalVisible(true)
    }

    const closeApproveModal = () => {
        setApprovedModalVisible(false)
    }

    const openRejectModal = () => {
        setRejectModalVisible(true)
    }

    const closeRejectModal = () => {
        setRejectModalVisible(false)
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then(() => { }).catch(e => {
            console.error(e);
        });
    }

    const setSelectedPlateAndGetBookingList = async (e) => {
        const plateNumber = e.key;
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        try {
            const responseToken = await instance.acquireTokenSilent(request);
            loadAndSetListBookingByEvent(responseToken.accessToken, plateNumber);
        } catch (e) {
            console.error(e);
        }

        setSelectedNumberPlate(plateNumber);
        form.setFieldsValue({ plate_number: plateNumber })
    };

    const createBookingDriver = async (data) => {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        try {
            const responseToken = await instance.acquireTokenSilent(request);
            const accessToken = responseToken.accessToken;

            await API.CreateBookingDriver(HeaderFarms(accessToken), data);

            await loadAndSetListBookingByEvent(accessToken, selectedNumberPlate);

            Report.success(
                `Booking Success`,
                `Please wait for feedback from GA`,
                "Okay",
            );

            closeAllModal()
            form.resetFields()
        } catch (err) {
            closeModalDetailBooking();
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        }
    };

    const handleBookingSubmit = (values) => {
        console.log('Booking details:', values);

        if (checkIsBookingOverlap(values))
            return confirmationAndSave(values);
        else
            return Report.info(
                "Car already book with another user",
                "Please select another time.",
                "Okay"
            );
    };

    const approveAndProcess = async () => {
        try {

            let getDetailProcess = selectedEvent.approval.detail[0]._id;

            const request = {
                ...loginRequest,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);

            await API.workflowApproved(HeaderFarms(response.accessToken), {
                approval_id_list: getDetailProcess,
            });

            Report.success(
                `Approved Success`,
                `Berhasil di setujui, Terima Kasih`,
                "Okay",
                async function () {
                    await loadAndSetListBookingByEvent(response.accessToken, selectedNumberPlate);
                }
            );
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const rejectAndProcess = async (values) => {
        try {

            let getDetailProcess = selectedEvent.approval.detail[0]._id;

            const request = {
                ...loginRequest,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);

            API.workflowReject(HeaderFarms(response.accessToken), {
                approval_id_list: getDetailProcess,
                msg: values.message
            })

            Report.success(
                `Reject Success`,
                `Booking Driver berhasil di tolak`,
                "Okay",
                async function () {
                    await loadAndSetListBookingByEvent(response.accessToken, selectedNumberPlate);
                    closeAllModal()
                }
            );
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

    const cancelAndProcess = async (values) => {
        try {

            let getDetailProcess = selectedEvent.approval._id;

            const request = {
                ...loginRequest,
                account: accounts[0]
            };

            const response = await instance.acquireTokenSilent(request);

            API.workflowCancel(HeaderFarms(response.accessToken), {
                approval_id: getDetailProcess,
                msg: values.message
            })

            Report.success(
                `Cancel Success`,
                `Booking Driver berhasil di cancel`,
                "Okay",
                async function () {
                    await loadAndSetListBookingByEvent(response.accessToken, selectedNumberPlate);
                    closeAllModal()
                }
            );
        } catch (err) {
            console.error(err);
            throw err;
        }
    };


    const updateBookingForDispatchDriver = async (values) => {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        try {
            const responseToken = await instance.acquireTokenSilent(request);
            const accessToken = responseToken.accessToken;

            await API.UpdateBookingDriver(HeaderFarms(accessToken), {
                driver: values.driver,
                id_record: selectedEvent.id_record,
                message: values.message
            });

            await loadAndSetListBookingByEvent(accessToken, selectedNumberPlate);

            Report.success(
                `Dispatch driver success`,
                `Driver akan melakukan konfirmasi dengan requestor`,
                "Okay",
            );

        } catch (err) {
            closeModalDetailBooking();
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        }

    }

    const approvedAndUpdateDriver = async (values) => {
        try {
            await approveAndProcess();
            await updateBookingForDispatchDriver(values)

            closeAllModal()
        } catch (err) {
            console.error(err);
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        }
    };

    function closeAllModal() {
        setModalDetailBookingVisible(false)
        setApprovedModalVisible(false)
        setBookingModalVisible(false)
        setRejectModalVisible(false)
    }

    const handleFinishApproved = (values) => {
        Modal.confirm({
            title: 'Important Confirmation',
            content: 'Are you sure?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                await approvedAndUpdateDriver(values);
            },
        });
    };

    const handleFinishReject = (values) => {
        Modal.confirm({
            title: 'Important Confirmation',
            content: 'Are you sure?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                if (selectedEvent.status_approve === "Approved")
                    await cancelAndProcess(values)
                else
                    await rejectAndProcess(values);
            },
        });
    };

    function customizeFormatBeforeSubmit(values) {
        const [date_from, date_to] = values.date_range;
        values.date_from = moment(date_from.format("YYYY-MM-DD HH"))
        values.date_to = moment(date_to.format("YYYY-MM-DD HH"))
        values.plate_number = selectedNumberPlate;
        delete values.date_range

        return values
    }

    function confirmationAndSave(values) {
        Modal.confirm({
            title: 'Important Confirmation',
            content: 'Are you sure?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
                try {
                    await createBookingDriver(
                        customizeFormatBeforeSubmit(values)
                    );
                } catch (error) {
                    console.error("onFinish error:", error);

                    Report.warning(
                        "Ops.. Ada kesalahan pada sistem",
                        "Maaf transaksi anda gagal, mohon coba lagi nanti.",
                        "Okay"
                    );

                    closeModalDetailBooking();
                }
            },
        });
    }

    function checkIsBookingOverlap(values) {

        let date_from = values.date_range[0]
        let date_to = values.date_range[1]

        let startMoment = moment(date_from, 'YYYY-MM-DD HH:mm');
        let endMoment = moment(date_to, 'YYYY-MM-DD HH:mm');

        let startTimestamp = startMoment.valueOf();
        let endTimestamp = endMoment.valueOf();

        let isOverlapping = true;

        listBooking.forEach((booking) => {
            let bookingStart = moment(booking.start, 'YYYY-MM-DD HH:mm').valueOf();
            let bookingEnd = moment(booking.end, 'YYYY-MM-DD HH:mm').valueOf();
            if (startTimestamp < bookingEnd && endTimestamp >= bookingStart) {
                isOverlapping = false;
            }
        });

        return isOverlapping;
    }

    async function loadDriverListAndSetDriver(token) {
        try {

            let result = await API.getJobPositionTalentaByid(HeaderFarms(token));
            setListDriver(result.data.data.employees);

        } catch (error) {
            console.error("Failed to get driver list:", error);
            throw error;
        }
    }

    async function loadAndSetListBookingByEvent(token, plateNumber) {
        try {
            setLoadingCalendar(true);
            let result = await API.ListBookingDriverByPlateNumber(HeaderFarms(token), plateNumber);
            const filteredBookings = filterByApprovalStatusNotReject(result);

            setListBookingAndChangeFormat(filteredBookings);
        } catch (error) {
            console.error("Failed to get and set list booking by event:", error);
            throw error;
        } finally {
            setLoadingCalendar(false);
        }
    }

    function filterByApprovalStatusNotReject(result) {
        if (Array.isArray(result.data)) {
            return result.data.filter(booking => booking.approval.status !== "Reject"
                && booking.approval.status !== "Cancel");
        } else {
            return [];
        }
    }

    async function loadBookingByRecordId(token, id_record) {
        try {

            let result = await API.ListBookingDriverByIdRecord(HeaderFarms(token), id_record);
            return result;
        } catch (error) {
            console.error("Failed to get detail booking by record ID:", error);
            throw error;
        }
    }

    async function openDetailBookingModalByRecordId() {
        if ((id_record) && selectedNumberPlate === null) {
            const bookingDetail = await loadAndSetNumberPlateDetailBookingByRecordId()
            setDefaultDateAndCloseLoading(bookingDetail.data[0]);
            openModalDetailBookingByRecordId(bookingDetail.data[0])
            setSelectedNumberPlate(bookingDetail.data[0].plate_number);
        }

        return setLoading(false)
    }

    function openModalDetailBookingByRecordId(bookingDetail) {
        const result = getChangeFormatEvent(bookingDetail)
        handleSelectEventAndOpenModal(result)
    }

    function setDefaultDateAndCloseLoading(date) {
        setDefaultDate(new Date(date.date_from));
        setLoading(false);
    }

    async function loadAndSetNumberPlateDetailBookingByRecordId() {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        try {
            const response = await instance.acquireTokenSilent(request);
            const bookingDetail = await loadBookingByRecordId(response.accessToken, id_record);

            await setNumberPlateAndSearchByNumberPlate(bookingDetail?.data[0].plate_number);
            return bookingDetail
        } catch (e) {
            console.error(e);
        }
    }

    async function setNumberPlateAndSearchByNumberPlate(plateNumber) {
        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        try {
            const responseToken = await instance.acquireTokenSilent(request);
            loadAndSetListBookingByEvent(responseToken.accessToken, plateNumber);
            setSelectedNumberPlate(plateNumber);
        } catch (e) {
            console.error(e);
        }

    }

    function setListBookingAndChangeFormat(result) {

        const transformedData = result.map(item => {
            return getChangeFormatEvent(item);
        });

        setListBooking(transformedData);
        setTimeout(() => {
            setLoadingCalendar(false)
        }, 500)
    }

    const eventPropGetter = (event) => {
        let newStyle = {
            border: "1px solid rgba(37, 41, 44, 0.5)",
            backgroundImage: "",
        };

        if (event.approval.status === "Approved") {
            newStyle.backgroundImage = "linear-gradient(to left, #39AC47, #39AC47)";
        } else if (event.approval.status === "Waiting Approval") {
            newStyle.backgroundImage = "linear-gradient(to left, #FF9900, #FF9900)";
        }

        return {
            className: "",
            style: newStyle
        };
    };

    function getChangeFormatEvent(item) {
        const startMoment = moment(item.date_from);
        const endMoment = moment(item.date_to);

        const driverDispatch = listDriver.find(
            driver =>
                driver.employment.employee_id === item.driver
        );

        return {
            title: (
                <>
                    <span style={{ fontSize: 13, marginLeft: 7 }}>{item.requestor.fullname}</span>
                    <br />
                    <span style={{ fontSize: 12, marginLeft: 7, marginTop: 2 }}>{`${item.approval.status}`}</span>
                </>
            ),
            approval: item.approval,
            requestor: item.requestor.fullname,
            start: moment(startMoment).startOf('day').hours(startMoment.hour()).minutes(0).toDate(),
            end: moment(endMoment).startOf('day').hours(endMoment.hour()).minutes(60).toDate(),
            start_event: moment(startMoment).startOf('day').hours(startMoment.hour()).minutes(0).toDate(),
            end_event: moment(endMoment).startOf('day').hours(endMoment.hour()).minutes(0).toDate(),
            plate_number: item.plate_number,
            status_approve: item.approval.status,
            objective: item.objective,
            destination: item.destination,
            approver: item.approval.detail[0].approved_by.fullname,
            driver: (
                <>
                    {
                        driverDispatch?.personal.avatar ? (
                            <Avatar
                                src={driverDispatch?.personal.avatar || 'default-avatar-url'}
                                size={50}
                                style={{ marginRight: 5 }}
                            />
                        ) : (
                            <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}>?</Avatar>
                        )
                    }
                    <span className='ml-2'>{driverDispatch?.personal.first_name || ' Not specified'}</span>
                </>
            ),
            id_record: item.id_record
        };
    }

    function customizeButtonToolbar() {
        const buttons = document.querySelectorAll('.rbc-btn-group button');
        buttons.forEach(button => {
            if (button.textContent.trim() === 'Back') {
                button.textContent = 'Previous Day';
            } else if (button.textContent.trim() === 'Next') {
                button.textContent = 'Next Day';
            }
        });
    }

    function applyCustomTimeSlotStyles() {
        const timeSlotLeftColumn = document.querySelectorAll('.rbc-time-column .rbc-timeslot-group');
        const timeSlotRight = document.querySelector('div.rbc-day-slot.rbc-time-column')?.children;

        timeSlotLeftColumn.forEach((slot, index) => {
            const slotText = slot.innerText.trim();
            const slotTime = moment(slotText, 'HH:mm');
            const hour = slotTime.hour();

            if ((hour >= 6 && hour < 10) || (hour >= 16 && hour < 21)) {
                slot.classList.add('red-time-slot');
                timeSlotRight[index].classList.add('red-time-slot');
                timeSlotRight[index].classList.add('watermark');
            }
        });
    }

    const plateMenu = (
        <>
            <Menu onClick={setSelectedPlateAndGetBookingList} selectedKeys={[selectedNumberPlate]}>
                {plateNumber.map(plate => (
                    <Menu.Item className='m-0' key={plate.key}>
                        {selectedNumberPlate === plate.key && <CheckCircleOutlined className='currentOdd' style={{ color: 'green' }} />}
                        {plate.key} / {plate.type}
                        {(isOddDay && plate.type === "Ganjil") || (!isOddDay && plate.type === "Genap")
                            ? <>   <Tag color="blue">Current Date</Tag></>
                            : null}
                    </Menu.Item>
                ))}
            </Menu>
            {
                loadingCalendar && (
                    <div className='spin-calendar d-flex justify-content-center m-3'>
                        <Spin size='small' spinning={loadingCalendar} />
                    </div>
                )
            }
        </>
    );

    async function loadListDriver() {
        if (listDriver.length === 0) {
            const request = {
                ...loginRequest,
                account: accounts[0],
            };

            try {
                const response = await instance.acquireTokenSilent(request);
                loadDriverListAndSetDriver(response.accessToken)
            } catch (e) {
                console.error(e);
            }
        }
    }

    function checkOuthentication() {
        if (!isAuthenticated) {
            if (instance.getAllAccounts().length > 0) {
                instance.setActiveAccount(accounts[0]);
            } else {
                handleLogin(instance);
            }
        }
    }

    function setTimeLabelRangePicker() {
        const timePanel = document.querySelector('.ant-picker-time-panel');
        if (timePanel && !timePanel.querySelector('.time-label')) {
            const headerView = timePanel.querySelector('.ant-picker-header-view');
            headerView.innerText = 'Time';
        }
    }

    const setReload = [
        currentDate, selectedNumberPlate, loading, location.search, inProgress, accounts, instance, isAuthenticated, listDriver
    ]


    useEffect(() => {
        const node = document.querySelector('.rbc-row-content');
        setTargetNode(node);

        const buttons = document.querySelectorAll('.rbc-btn-group button');
        buttons.forEach(button => {
            button.classList.add('btn', 'btn-primary');
        });

        checkOuthentication()

        customizeButtonToolbar()
        applyCustomTimeSlotStyles();
        openDetailBookingModalByRecordId();
        loadListDriver();

    }, setReload);

    if (loading) {
        return <Spin size='large' spinning={loading} style={{ display: "flex", justifyContent: "center" }} />;
    }

    return (
        <>
            <div className="container white-back-cr">
                <div className="mt-2">
                    <div className="mb-1">
                        {
                            !selectedNumberPlate && (
                                <Alert style={{ background: "#FFFFFF", borderRadius: 20, marginBottom: 20 }}
                                    message="Please select operational car"
                                    type="info"
                                    showIcon />
                            )
                        }
                        <Button type="primary" onClick={openBookingModal}>
                            Booking Now
                        </Button>
                    </div>
                    <Spin className='text-center' spinning={loading} tip="Loading...">
                        {!loading && (
                            <Calendar
                                localizer={localizer}
                                events={listBooking}
                                toolbar={true}
                                startAccessor="start"
                                endAccessor="end"
                                showMultiDayTimes
                                defaultView="day"
                                formats={{
                                    timeGutterFormat: 'HH:mm',
                                }}
                                views={['day',]}
                                defaultDate={defaultDate}
                                onSelectEvent={handleSelectEventAndOpenModal}
                                onNavigate={handleNavigateDate}
                                eventPropGetter={eventPropGetter}

                            />
                        )}
                    </Spin>
                    {targetNode && ReactDOM.createPortal(plateMenu, targetNode)}

                </div>
            </div>
            <Modal
                title="Detail Booking"
                id='modal_detail'
                visible={modalDetailBookingVisible}
                onCancel={closeModalDetailBooking}
                footer={null}
                transitionName=""
            >
                {selectedEvent && (
                    <>
                        <div className="container">
                            <div className="row mb-2">
                                <div className="col-md-3 col-4"><strong>Requestor:</strong></div>
                                <div className="col-md-9 col-6">{selectedEvent.requestor}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3 col-4"><strong>Plate:</strong></div>
                                <div className="col-md-9 col-6">
                                    {selectedEvent.plate_number} &nbsp;
                                    {<Tag color="blue">{plateNumber.find(
                                        plate => selectedEvent.plate_number === plate.key)?.type}</Tag>}
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3 col-4"><strong>Destination:</strong></div>
                                <div className="col-md-9 col-6">{selectedEvent.destination}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3 col-4"><strong>Objective:</strong></div>
                                <div className="col-md-9 col-6">{selectedEvent.objective}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3 col-4"><strong>Start:</strong></div>
                                <div className="col-md-9 col-6">{moment(selectedEvent.start_event).format('YYYY-MM-DD HH:mm')}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3 col-4"><strong>End:</strong></div>
                                <div className="col-md-9 col-6">{moment(selectedEvent.end_event).format('YYYY-MM-DD HH:mm')}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3 col-4"><strong>Driver:</strong></div>
                                <div className="col-md-9 col-6">{selectedEvent.driver}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3 col-4"><strong>Status:</strong></div>
                                <div className="col-md-9 col-6">
                                    <Tag style={{ fontSize: 13 }} color={selectedEvent.status_approve === "Approved" ? 'blue' : 'gold'}>
                                        {selectedEvent.status_approve}
                                    </Tag>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3 col-4"><strong>Approver :</strong></div>
                                <div className="col-md-9 col-6">{selectedEvent.approver}</div>
                            </div>
                            {
                                selectedEvent.status_approve !== "Approved" && selectedEvent.approval.detail[0].approved_by.nik ===
                                    JSON.parse(localStorage.getItem("session_signin")).nik ? (
                                    <>
                                        <div className="row mt-3 text-center">
                                            <div className="col-md-12 mt-3">
                                                <Button type="primary" onClick={openApproveModal} style={{ marginRight: '10px' }}>
                                                    Approved
                                                </Button>
                                                <Button type="danger" onClick={openRejectModal}>
                                                    Reject
                                                </Button>
                                            </div>
                                        </div>
                                    </>
                                ) : (<>
                                    {
                                        selectedEvent.approval.created_by === JSON.parse(localStorage.getItem("session_signin")).id && (
                                            <div className="row mt-3 text-center">
                                                <div className="col-md-12 mt-3">
                                                    <Button type="danger" onClick={openRejectModal}>
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                    }
                                </>)
                            }

                        </div>
                    </>
                )}
            </Modal>
            <Modal
                title="Booking Driver"
                visible={bookingModalVisible}
                onCancel={closeBookingModal}
                footer={null}
                id='modal_form'
                transitionName=''
            >
                <Alert message="Please check schedule car operational before booking." className='mb-3' type="info" showIcon />
                <Form layout="vertical"
                    onFinish={handleBookingSubmit}
                    form={form}
                    size="middle"
                >
                    <Form.Item
                        name="plate_number"
                        label="Plate Number"
                        rules={[{ required: false, message: 'Please select the plate number!' }]}
                    >
                        <Input disabled defaultValue={selectedNumberPlate}></Input>
                    </Form.Item>
                    <Form.Item
                        name="destination"
                        label="Destination"
                        rules={[{ required: true, message: 'Please input the destination!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="objective"
                        label="Objective"
                        rules={[{ required: true, message: 'Please input the objective!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                name="date_range"
                                label="Date Range"
                                rules={[{ required: true, message: 'Please select the date and time range!' }]}
                            >
                                <RangePicker
                                    onFocus={() => setTimeLabelRangePicker()}
                                    showTime={{
                                        format: 'HH',
                                        minuteStep: 60,
                                    }}
                                    format="YYYY-MM-DD HH"
                                />

                            </Form.Item>
                        </Col>

                    </Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal >
            <Modal
                title="Select Driver"
                visible={approvedModalVisible}
                onCancel={closeApproveModal}
                footer={null}
                transitionName=''
                id="approved_booking"
            >
                <Form layout="vertical"
                    onFinish={handleFinishApproved}
                    size="large"
                >
                    <Alert message="Please check schedule driver before approved." className='mb-3' type="info" showIcon />
                    <Form.Item
                        name="driver"
                        label="Drivers"
                        rules={[{ required: true, message: 'Please select the driver!' }]}
                    >
                        <Select placeholder="Select Driver">
                            {listDriver.map(plate => (
                                <Option key={plate.personal.barcode} value={plate.personal.barcode}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar src={plate.personal.avatar} size="default" style={{ marginRight: 8 }} />
                                        {plate.personal.first_name}
                                    </div>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Pesan ke driver"
                        name="message"
                        rules={[
                            {
                                required: false,
                                message: "Please input your Message!",
                            },
                        ]}
                    >
                        <Input.TextArea
                            showCount
                            style={{ height: "150px", width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item className='text-center'>
                        <Button size='middle' type="primary" htmlType="submit">
                            Approved
                        </Button>
                    </Form.Item>
                </Form>
            </Modal >
            <Modal
                title="Write the reason"
                footer={null}
                visible={rejectModalVisible}
                onCancel={closeRejectModal}
                transitionName=''
            >
                <Form
                    name="basic"
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={handleFinishReject}
                    autoComplete="off"
                    layout="vertical"
                >
                    <Form.Item
                        label=""
                        name="message"
                        rules={[
                            {
                                required: false,
                                message: "Please input your Message!",
                            },
                        ]}
                    >
                        <Input.TextArea
                            showCount
                            style={{ height: "150px", width: "100%" }}
                            minLength={10}
                        />
                    </Form.Item>
                    <Form.Item
                        className="mt-3"
                        wrapperCol={{
                            xs: {
                                offset: 8, span: 18
                            },
                            sm: {
                                offset: 7, span: 18
                            },
                            lg: { offset: 8, span: 18 }
                        }}

                    >
                        <Button type="danger" htmlType="submit">
                            Reject
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default ScheduleDriver;
