import React, { Fragment, useEffect, useState } from "react";
import { Badge, Affix, Button, Spin, DatePicker, Select, Statistic } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { Loading, Report } from "notiflix";
import moment from "moment";

import { PlusOutlined } from '@ant-design/icons';
import "./styles.css";

import { callMsGraph } from "../../../utils/graph";
import { loginRequest } from "../../../config/api-config";

import HeaderFarms from "../../../utils/header_farms";
import API from "../../../services";


function FuelDeliveryHistory() {

    let navigate = useNavigate();
    const { Option } = Select;

    const { instance, accounts } = useMsal();
    const [historyDelivery, setHistoryDelivery] = useState([]);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [loading, setLoading] = useState(false)
    const isAuthenticated = useIsAuthenticated();
    const [totalFuel, setTotalFuel] = useState(0)
    const [filter, setFilter] = useState(null)

    const [FT,] = useState(['TS-9657-MMS', 'FT-002', 'FT-003', 'Tangki A', 'Tangki B'])


    function handleStartDateChange(date) {
        setStartDate(date);
        sessionStorage.setItem('startDate', date);
    }

    function handleEndDateChange(date) {
        setEndDate(date);
        sessionStorage.setItem('endDate', date);
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then((res) => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    function getHistory(accessToken, startDate, endDate) {

        API.HistoryRequestDelivery(HeaderFarms(accessToken), { startDate: startDate, endDate: endDate }).then(res => {
            totalFuelCount(filter, res.data)
            setHistoryDelivery(res.data)
            setLoading(false)
        })
            .catch((err) => {
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, this application is experiencing problems",
                    "Okay"
                );
                Loading.remove();
            });

    }

    function search() {
        setLoading(true)
        handleFilterByDate()
    }

    function handleChange(value) {
        setFilter(value)
        totalFuelCount(value, historyDelivery)
    }

    function totalFuelCount(filter, requestsFuel) {
        let total = 0;
        if (filter) {
            requestsFuel.forEach(request => {
                if (request.fuel_truck === filter)
                    total += request.issued_total;
            });
        } else {
            requestsFuel.forEach(request => {
                total += request.issued_total;
            });
        }

        setTotalFuel(total)
    }

    function handleFilterByDate() {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {

                callMsGraph(responseToken.accessToken).then((_) => {

                    // Convert start and end dates to JavaScript Date objects
                    let startDateFormat = new Date(startDate);
                    let endDateFormat = new Date(endDate);

                    // Retrieve startDate and endDate from sessionStorage
                    const savedEndDate = sessionStorage.getItem('endDate');
                    const savedStartDate = sessionStorage.getItem('startDate');

                    // Set startDate and endDate if they exist in sessionStorage
                    if (savedStartDate) {
                        startDateFormat = new Date(savedStartDate)
                        setStartDate(savedStartDate)
                    }
                    if (savedEndDate) {
                        endDateFormat = new Date(savedEndDate)
                        setEndDate(savedEndDate)
                    }

                    // Set the time part of the dates to 00:00:00 for start date and 23:59:59 for end date
                    startDateFormat.setHours(0, 0, 0, 0);
                    endDateFormat.setHours(23, 59, 59, 999);

                    getHistory(responseToken.accessToken, startDateFormat, endDateFormat)
                });
            })
            .catch((e) => {
                handleLogin(instance)
            });
    }

    const getAllDeliveryFuelTruck = () => {
        for (const truck of FT) {
            API.DeliveryLatest(HeaderFarms(), truck);
        }
    }

    function goDetail(id) {
        navigate(`/fms/delivery/view/${id}`);
    }

    useEffect(() => {
        search()
        getAllDeliveryFuelTruck()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    return (
        <Fragment>
            <div className="container-fluid p-0 mt-3">
                <div className="row">
                    <div className="col">
                        <Affix offsetTop={10}>
                            <div className="d-flex justify-content-start">
                                <Button type="primary" onClick={() => navigate("/fms/delivery/create")} size="middle" icon={<PlusOutlined />}>
                                    New Delivery
                                </Button>
                            </div>
                        </Affix>
                    </div>
                    <div className="col" style={{ marginTop: -10 }}>
                        <Statistic title="Fuel Issued (LT)" value={totalFuel} />
                    </div>
                </div>
                <div className="row mb-4 mt-4">
                    <div className="col">
                        <DatePicker
                            size="small"
                            className="m-1"
                            onChange={handleStartDateChange}
                            placeholder="Select Date"
                            value={moment(startDate)}
                            style={{ width: 120 }}
                            allowClear={false}
                        />
                        <DatePicker
                            size="small"
                            className="m-1"
                            onChange={handleEndDateChange}
                            placeholder="End Date"
                            value={moment(endDate)}
                            style={{ width: 120 }}
                            allowClear={false}
                        />
                        <Button className="mt-2 m-1" type="primary" onClick={search} size="small">
                            Cari
                        </Button>
                    </div>
                </div>

                <div className="col-md-12 d-flex justify-content-end">
                    <label style={{ marginRight: 10, marginTop: 5 }}>Truck</label>
                    <Select
                        style={{
                            width: 140,
                        }}
                        size="middle"
                        onChange={handleChange}
                        placeholder="Pilih FT"
                    >
                        {
                            ['TS-9657-MMS', 'FT-002', 'FT-003', 'Tangki A', 'Tangki B'].map(truck => {
                                return (
                                    <Option value={truck}>{truck}</Option>
                                )
                            })
                        }
                    </Select>
                </div>
                <div className="row mt-4">
                    <div className="col-md-10 offset-md-1">
                        {
                            loading && (
                                <div className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                                    <Spin size="large" />
                                </div>
                            )
                        }
                        {
                            historyDelivery.length > 0 &&
                            historyDelivery.map((data) => {
                                if (filter) {
                                    if (data.fuel_truck === filter) {
                                        return (
                                            <Fragment key={data.id_record}>
                                                <Badge.Ribbon text={data?.issued_total + " LT"}>
                                                    <div
                                                        className="list-style p-2 px-3"
                                                        onClick={() => goDetail(data.record_id)}
                                                    >
                                                        <div className="title d-flex justify-content-between">
                                                            <div className="fs-7 mb-0">{data.fuel_truck}</div>
                                                            <div className="fs-7 mb-0" style={{ color: "#B7B7B7", fontSize: 11 }}>
                                                                Sisa: {data.remaining_fuel_period} - Pengeluaran: {data.issued_period_before} - <span
                                                                    style={
                                                                        data.status_approval.status !== "Approved" ?
                                                                            { background: "#E0710F", padding: 3, color: "#FFFFFF" } :
                                                                            { background: "#1890FF", padding: 3, color: "#FFFFFF" }}>
                                                                    {data.status_approval.status}</span>
                                                            </div>

                                                        </div>
                                                        <div className="title mt-2">
                                                            <p style={{ color: "#B7B7B7", fontSize: 11 }}>{data.operator?.fullname}</p>
                                                        </div>
                                                        <div className="hour d-flex justify-content-between">
                                                            <p className="fs-7">
                                                                <span style={{ color: "#1890FF", fontSize: 11, fontWeight: "bold" }}>{data.fuel_truck}</span> - <span style={{ color: "#B7B7B7", fontSize: 11 }}> {data.created_by} - {moment(data.date).format('YYYY-MM-DD')} {moment(data.time).format('HH:mm')}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Badge.Ribbon>
                                            </Fragment>
                                        );
                                    }
                                } else {
                                    return (
                                        <Fragment key={data.id_record}>
                                            <Badge.Ribbon text={data.issued_total + " LT"}>
                                                <div
                                                    className="list-style p-2 px-3"
                                                    onClick={() => goDetail(data.record_id)}
                                                >
                                                    <div className="title d-flex justify-content-between">
                                                        <div className="fs-7 mb-0">{data.fuel_truck}</div>
                                                        <div className="fs-7 mb-0" style={{ color: "#B7B7B7", fontSize: 11 }}>
                                                            Sisa: {data.remaining_fuel_period} - Pengeluaran: {data.issued_period_before} - <span
                                                                style={
                                                                    data.status_approval.status !== "Approved" ?
                                                                        { background: "#E0710F", padding: 3, color: "#FFFFFF" } :
                                                                        { background: "#1890FF", padding: 3, color: "#FFFFFF" }}>
                                                                {data.status_approval.status}</span>
                                                        </div>

                                                    </div>
                                                    <div className="title mt-2">
                                                        <p style={{ color: "#B7B7B7", fontSize: 11 }}>{data.operator?.fullname}</p>
                                                    </div>
                                                    <div className="hour d-flex justify-content-between">
                                                        <p className="fs-7">
                                                            <span style={{ color: "#1890FF", fontSize: 11, fontWeight: "bold" }}>{data.fuel_truck}</span> - <span style={{ color: "#B7B7B7", fontSize: 11 }}> {data.created_by} - {moment(data.date).format('YYYY-MM-DD')} {moment(data.time).format('HH:mm')}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Badge.Ribbon>
                                        </Fragment>
                                    );
                                }
                                // Mengembalikan nilai default jika tidak memenuhi kriteria filter
                                return null;
                            })
                        }

                        {
                            historyDelivery.length === 0 &&
                            (
                                <div className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                                    No more data
                                </div>
                            )

                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default FuelDeliveryHistory;
