import React, { Fragment, useEffect, useState } from "react";

import { Badge, Affix, Button, Spin, DatePicker, Select, Statistic, Alert } from "antd";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router";
import { Loading, Notify, Report } from "notiflix";
import moment from "moment";

import { PlusOutlined } from '@ant-design/icons';
import "./styles.css";

import { callMsGraph } from "../../../utils/graph";
import { loginRequest } from "../../../config/api-config";

import { checkNetworkType } from "../../../utils/check_network";
import { readFromIndexDB } from "../../../utils/idb";
import HeaderFarms from "../../../utils/header_farms";
import API from "../../../services";


function FuelRequestHistory() {

    let navigate = useNavigate();
    const { Option, OptGroup } = Select;

    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [totalFuelRequestIssued, setTotalFuelRequestIssued] = useState(0)
    const [totalFuelDeliveryIssued, setTotalFuelDeliveryIssued] = useState(0)
    const [totalFuelDeliveryRemaining, setTotalFuelDeliveryRemaining] = useState(0)

    const [LabelPeriodeDeliveryDate, setLabelPeriodeDeliveryDate] = useState(null)
    const [lastDeliveryByFuelTruck, setLastDeliveryByFuelTruck] = useState([]);

    const [loadingLoadServer, setLoadingLoadServer] = useState(false)
    const [loadingLoadIDB, setLoadingLoadIDB] = useState(false)

    const [historyDelivery, setHistoryDelivery] = useState([]);
    const [historyRequest, setHistoryRequest] = useState([]);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());

    const [valueInputSelectedDelivery, setValueInputSelectedDelivery] = useState(null);
    const [valueInputSelectedLastDelivery, setValueInputSelectedLastDelivery] = useState(null);

    const [requests, setRequests] = useState([]);
    const [isOnline, setIsOnline] = useState(window.navigator.onLine);

    Notify.init({
        position: 'center-center',
        distance: '50px',
        timeout: 2000,
    });

    function handleStartDateChange(date) {
        setStartDate(date);
        sessionStorage.setItem('startDate', date);
    }

    function handleEndDateChange(date) {
        setEndDate(date);
        sessionStorage.setItem('endDate', date);
    }

    function handleLogin(instance) {
        instance.loginRedirect(loginRequest).then((res) => {
        })
            .catch(e => {
                console.error(e);
            });
    }

    const getArea = (token) => {
        API.Area(HeaderFarms(token), null).then(res => {
        }).catch(err => {
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        })
    }

    const getEquipments = (token) => {
        API.Equipments(HeaderFarms(token), null).then(res => {
        }).catch(err => {
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        })
    }

    const getOperator = (token) => {
        API.Operators(HeaderFarms(token), null).then(res => {
        }).catch(err => {
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        })
    }

    async function getHistoryRequestFuel(accessToken, startDate, endDate) {
        try {
            const res = await API.HistoryRequestFuel(HeaderFarms(accessToken),
                { startDate: startDate, endDate: endDate }
            );

            setHistoryRequest(res.data);
            countTotalFuelRequest(res.data);
            setLoadingLoadServer(false);
        } catch (error) {
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
            Loading.remove();
        }
    }

    const getDeliveryRequest = (accessToken, startDate, endDate) => {
        API.HistoryRequestDelivery(
            HeaderFarms(accessToken),
            { startDate: startDate, endDate: endDate }
        ).then(res => {
            const { data } = res;
            setHistoryDelivery(getLabelSelectDelivery(data));
        });
    }

    const getLabelSelectDelivery = (deliveryRequests) => {

        const groupedDeliveries = {};
        deliveryRequests.forEach((delivery) => {
            const deliveryActualDate = moment(delivery.date).format("YYYY-MM-DD");
            if (!groupedDeliveries[deliveryActualDate]) {
                groupedDeliveries[deliveryActualDate] = [];
            }
            groupedDeliveries[deliveryActualDate].push(delivery);
        });

        const DTO = [];
        Object.keys(groupedDeliveries).forEach(date => {
            groupedDeliveries[date].forEach(delivery => {
                let dateActualLabel = '';
                if (date === moment().format("YYYY-MM-DD")) {
                    dateActualLabel = moment(delivery.date).format("HH:mm")
                        + " / " + delivery.fuel_truck
                } else {
                    dateActualLabel = moment(delivery.date).format("HH:mm")
                        + " / " + delivery.fuel_truck
                }

                const label = `${dateActualLabel}`;
                delivery.labelSelect = label;
                DTO.push(delivery);
            });
        });

        return DTO;
    }

    const renderAndGroupingSelectOptions = (deliveries) => {
        const groupedOptions = {};
        deliveries.forEach(delivery => {
            const date = moment(delivery.date).format("YYYY-MM-DD");
            if (!groupedOptions[date]) {
                groupedOptions[date] = [];
            }
            groupedOptions[date].push(
                <Option key={delivery.id} value={delivery.id}>
                    {delivery.labelSelect ||
                        moment(delivery.date).format("HH:mm") + " / " + delivery?.fuel_truck}
                </Option>
            );
        });

        return Object.keys(groupedOptions).map(date => (
            <OptGroup key={date} label={date}>
                {groupedOptions[date]}
            </OptGroup>
        ));
    }

    function searchHistoryRequest() {
        setLoadingLoadServer(true)
        getDataFuelRequestByPeriodDate()
        resetFilters()
    }

    const resetFilters = () => {
        setLabelPeriodeDeliveryDate(null)
        setValueInputSelectedDelivery(null);
        setValueInputSelectedLastDelivery(null)
        setTotalFuelDeliveryRemaining(0)
        setTotalFuelDeliveryIssued(0)
    };

    const getHistoryDeliveryAndSetLocalTimeById = (id) => {
        let deliveryRequest =
            historyDelivery.find(
                delivery =>
                    delivery._id === id
            )

        // Set localTime
        deliveryRequest.fromPeriodLocalTime =
            moment(deliveryRequest.issued_period_date_from)
        deliveryRequest.toPeriodLocalTime =
            moment(deliveryRequest.issued_period_date_to)

        return deliveryRequest
    }

    const getLastHistoryDeliveryAndSetLocalTimeById = (id) => {

        let deliveryRequest =
            lastDeliveryByFuelTruck.find(
                delivery =>
                    delivery._id === id
            )

        // Set localTime
        deliveryRequest.fromPeriodLocalTime =
            moment(deliveryRequest.issued_period_date_from)
        deliveryRequest.toPeriodLocalTime =
            moment(deliveryRequest.issued_period_date_to)

        return deliveryRequest
    }

    const getHistoryRequestsByDeliveryByFuelTruck = async (historyDelivery) => {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        try {

            const responseToken = await instance.acquireTokenSilent(request);

            await callMsGraph(responseToken.accessToken)

            const historyRequest = await API.HistoryRequestFuel(
                HeaderFarms(responseToken.accessToken),
                {
                    startDate: historyDelivery.fromPeriodLocalTime,
                    endDate: historyDelivery.toPeriodLocalTime
                }
            );

            let resultFilterByFT =
                historyRequest.data.filter(
                    requestFuel =>
                        requestFuel.fuel_truck === historyDelivery.fuel_truck
                )

            return resultFilterByFT;
        } catch (e) {
            console.log(e)
        }
    }

    const getHistoryRequestsByLastDelivery = async (historyDelivery) => {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        try {

            const responseToken = await instance.acquireTokenSilent(request);

            await callMsGraph(responseToken.accessToken)

            const historyRequest = await API.HistoryRequestFuel(
                HeaderFarms(responseToken.accessToken),
                {
                    startDate: historyDelivery.toPeriodLocalTime,
                    endDate: moment()
                }
            );

            let resultFilterByFT =
                historyRequest.data.filter(
                    requestFuel =>
                        requestFuel.fuel_truck === historyDelivery.fuel_truck
                )

            return resultFilterByFT;
        } catch (e) {
            console.log(e)
        }
    }

    const renderFilterHistoryDeliveryByRequestFuel = async (resultGetDeliveryRequest) => {
        setLoadingLoadServer(true)

        let resultHistoryRequest =
            await getHistoryRequestsByDeliveryByFuelTruck(resultGetDeliveryRequest)

        setHistoryRequest(resultHistoryRequest)
        setTotalFuelDeliveryRemaining(resultGetDeliveryRequest.remaining_fuel_period)
        setTotalFuelRequestIssued(resultGetDeliveryRequest.issued_period_before)
        setTotalFuelDeliveryIssued(resultGetDeliveryRequest.issued_total)
        setLoadingLoadServer(false)
    }

    const renderFilterLastHistoryDeliveryByRequestFuel = async (resultGetDeliveryRequest) => {
        setLoadingLoadServer(true)

        let resultHistoryRequest =
            await getHistoryRequestsByLastDelivery(resultGetDeliveryRequest)

        let resultTotalFuelRequest = countTotalLastFuelDelivery(
            resultHistoryRequest, resultGetDeliveryRequest.fuel_truck
        )

        setTotalFuelDeliveryRemaining(
            resultGetDeliveryRequest.issued_total - resultTotalFuelRequest
        )
        setTotalFuelDeliveryIssued(resultGetDeliveryRequest.issued_total)
        setHistoryRequest(resultHistoryRequest)
        setLoadingLoadServer(false)
    }


    const getLastDeliveryFuelTruck = async (truckIds) => {
        let lastDeliveryRequests = [];

        for (const ft of truckIds) {
            try {
                const latest = await API.DeliveryLatest(HeaderFarms(), ft);
                if (latest.data) {
                    latest.data.id = latest.data._id

                    lastDeliveryRequests.push(latest.data);
                }
            } catch (err) {
                console.error(err);
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, Failed load latest delivery",
                    "Okay"
                );
            }
        }

        setLastDeliveryByFuelTruck(lastDeliveryRequests)
    }

    function handleChangeFilterFuelDelivery(id) {

        resetFilters()

        let resultGetDeliveryRequest = getHistoryDeliveryAndSetLocalTimeById(id)

        renderFilterHistoryDeliveryByRequestFuel(resultGetDeliveryRequest)

        setLabelPeriodDelivery(
            resultGetDeliveryRequest.fromPeriodLocalTime,
            resultGetDeliveryRequest.toPeriodLocalTime,
        )

        setValueInputSelectedDelivery(id)
    }

    function handleChangeSelectedLastFuelDelivery(id) {

        resetFilters()

        let resultGetLastDeliveryRequest = getLastHistoryDeliveryAndSetLocalTimeById(id)

        renderFilterLastHistoryDeliveryByRequestFuel(resultGetLastDeliveryRequest)

        setLabelPeriodDelivery(
            resultGetLastDeliveryRequest.toPeriodLocalTime,
            moment(), // this Moment time
        )

        setValueInputSelectedLastDelivery(id)

    }

    const setLabelPeriodDelivery = (fromPeriod, toPeriod) => {
        setLabelPeriodeDeliveryDate(
            fromPeriod.format("YYYY-MM-DD HH:mm")
            + " > " +
            toPeriod.format("YYYY-MM-DD HH:mm"))
    }

    function countTotalFuelRequest(requestsFuel) {
        let total = 0;
        requestsFuel.forEach(request => {
            total += request.amount_issued;
        });

        setTotalFuelRequestIssued(total)

        return total
    }

    function countTotalLastFuelDelivery(requestsFuel, fuelTruck) {
        let total = 0;
        requestsFuel.forEach(request => {
            if (request.fuel_truck === fuelTruck)
                total += request.amount_issued;
        });

        setTotalFuelRequestIssued(total)
        return total
    }


    const getCurrentDate = () => {
        // Convert start and end dates to JavaScript Date objects
        let startDateFormat = new Date(startDate);
        let endDateFormat = new Date(endDate);

        // Retrieve startDate and endDate from sessionStorage
        const savedEndDate = sessionStorage.getItem('endDate');
        const savedStartDate = sessionStorage.getItem('startDate');

        // Set startDate and endDate if they exist in sessionStorage
        if (savedStartDate) {
            startDateFormat = new Date(savedStartDate)
            setStartDate(savedStartDate)
        }
        if (savedEndDate) {
            endDateFormat = new Date(savedEndDate)
            setEndDate(savedEndDate)
        }

        // Set the time part of the dates to 00:00:00 for start date and 23:59:59 for end date
        startDateFormat.setHours(0, 0, 0, 0);
        endDateFormat.setHours(23, 59, 59, 999);

        return { startDateFormat, endDateFormat }
    }

    function getDataFuelRequestByPeriodDate() {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {

                callMsGraph(responseToken.accessToken).then((_) => {

                    const currentDate = getCurrentDate()

                    getHistoryRequestFuel(
                        responseToken.accessToken,
                        currentDate.startDateFormat,
                        currentDate.endDateFormat
                    )

                    getDeliveryRequest(
                        responseToken.accessToken,
                        currentDate.startDateFormat,
                        currentDate.endDateFormat
                    )

                    getLastDeliveryFuelTruck(
                        ['FT-002', 'FT-003', 'TS-9657-MMS', 'Tangki A', 'Tangki B']
                    );

                    getEquipments(responseToken.accessToken)
                    getOperator(responseToken.accessToken)
                    getArea(responseToken.accessToken)
                });
            })
            .catch((e) => {
                handleLogin(instance)
            });
    }

    function goDetail(id) {
        navigate(`/fms/request/view/${id}`);
    }

    const convertingArrayBufferToJson = (dataArray) => {
        // Mengumpulkan data yang telah di-parse menjadi JSON dalam sebuah array
        const parsedDataArray = dataArray.map(data => {
            try {
                const arrayBuffer = data.requestData.body;
                let str = new TextDecoder().decode(arrayBuffer);
                // Extract the part that contains the JSON data
                let boundary = str.split('\r\n')[0]; // Get the boundary from the first line
                let parts = str.split(boundary);
                let jsonData = null;
                for (let i = 0; i < parts.length; i++) {
                    if (parts[i].includes('name="json_data"')) {
                        let jsonPart = parts[i];
                        let startIndex = jsonPart.indexOf('{');
                        let endIndex = jsonPart.lastIndexOf('}') + 1;
                        let jsonString = jsonPart.substring(startIndex, endIndex);
                        try {
                            jsonData = JSON.parse(jsonString);
                            return jsonData
                        } catch (e) {
                            console.error('Error parsing JSON:', e);
                        }
                        break;
                    }
                }

            } catch (error) {
                console.error('Error parsing data:', error);
                return null;
            }
        });

        return parsedDataArray
    }

    const fetchDataIDB = async () => {
        try {
            setLoadingLoadIDB(true)
            const dataArray = await readFromIndexDB()
            setRequests(
                convertingArrayBufferToJson(dataArray)
            )
            setLoadingLoadIDB(false)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // const syncManual = () => {
    //     if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
    //         navigator.serviceWorker.controller.postMessage({ msg: 'MANUAL_SYNC' });
    //     } else {
    //         console.error('Service worker not available or not controlling the page');
    //     }
    // }

    useEffect(() => {

        checkNetworkType()
        searchHistoryRequest()
        fetchDataIDB()

        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        if ('serviceWorker' in navigator) {
            const messageHandler = async (event) => {
                try {
                    if (event.data && event.data.msg) {

                        if (event.data.msg === "READ_SYNC") {
                            fetchDataIDB()
                        }

                        if (event.data.msg === "PROGRESS_SYNC") {
                            setIsOnline(true)
                            Notify.success("Sedang Mengirim ke server. Jangan tinggalkan halaman ini", {
                                timeout: 10000
                            })
                        }

                        if (event.data.msg === "OK_SYNC") {

                            fetchDataIDB()
                            Notify.success(`${event.data.detail}`, {
                                timeout: 3000,
                            });

                        }

                        if (event.data.msg === "NOOK_SYNC") {
                            Notify.failure("NO_OK_SYNC")

                            Report.success(
                                `Gagal terkirim ke server ..`,
                                "Mohon mohon tunggu beberapa saat. ",
                                "Ok"
                            )
                        }

                        if (event.data.msg === "COMPLETE_SYNC") {

                            setTimeout(() => {
                                setRequests([])
                                searchHistoryRequest()
                                Report.success(
                                    `Berhasil terkirim ke server ..`,
                                    "Mohon mohon lakukan pengecekan transaksi. ",
                                    "Ok"
                                )
                            }, 2000)
                        }
                    }
                } catch (error) {
                    console.error('Error processing service worker message:', error);
                }
            };

            navigator.serviceWorker.addEventListener("message", messageHandler);

            return () => {
                navigator.serviceWorker.removeEventListener("message", messageHandler);
                window.removeEventListener('online', handleOnline);
                window.removeEventListener('offline', handleOffline);

            };
        }

    }, [isAuthenticated,]);

    return (
        <Fragment>
            <div className="container-fluid p-0 mt-3">
                <div className="row">
                    <div className="col-sm-12 col-xs-12 col-md-3 mb-4">
                        <Affix offsetTop={10}>
                            <div className="d-flex justify-content-start">
                                <Button type="primary"
                                    onClick={() => navigate("/fms/request/create")} size="middle" icon={<PlusOutlined />}>
                                    Permintaan Baru
                                </Button>
                            </div>
                        </Affix>
                    </div>
                    <div className="col text-center">
                        <Statistic title="Penyaluran" value={(totalFuelRequestIssued ?? 0).toFixed(2)} />
                    </div>
                    <div className="col text-center">
                        <Statistic title="Sisa" value={(totalFuelDeliveryRemaining ?? 0).toFixed(2)} />
                    </div>
                    <div className="col text-center">
                        <Statistic title="Jumlah Solar" value={(totalFuelDeliveryIssued ?? 0).toFixed(2)} />
                    </div>
                </div>
                <div className="row mb-2 mt-4">
                    <div className="col">
                        <DatePicker
                            size="middle"
                            className="m-1"
                            onChange={handleStartDateChange}
                            placeholder="Select Date"
                            value={moment(startDate)}
                            style={{ width: 130 }}
                            allowClear={false}
                        />
                        <DatePicker
                            size="middle"
                            className="m-1"
                            onChange={handleEndDateChange}
                            placeholder="End Date"
                            value={moment(endDate)}
                            style={{ width: 130 }}
                            allowClear={false}
                        />
                        <Button className="mt-2 m-1" type="primary" onClick={searchHistoryRequest} size="small">
                            Cari
                        </Button>
                        {/* {
                            requests.length > 0 && (
                                <Button className="mt-2 m-1" type="primary" onClick={syncManual} size="small">
                                    Sinkronisasi Data
                                </Button>
                            )
                        } */}
                    </div>
                </div>
                <div className="row">
                    <div key={"213131"} style={{ marginLeft: 5, marginRight: -12 }} className="col-md-4">
                        <Select
                            style={{
                                width: "100%",
                                marginTop: 10
                            }}
                            size="middle"
                            onChange={handleChangeFilterFuelDelivery}
                            value={valueInputSelectedDelivery}
                            placeholder="Riwayat Pengisian FT"
                        >
                            {renderAndGroupingSelectOptions(historyDelivery)}
                        </Select>
                    </div>
                    <div key={"21313221"} style={{ marginLeft: 5, marginRight: -12 }} className="col-md-4">
                        <Select
                            style={{
                                width: "100%",
                                marginTop: 10
                            }}
                            size="middle"
                            onChange={handleChangeSelectedLastFuelDelivery}
                            value={valueInputSelectedLastDelivery}
                            placeholder="Penyaluran Unit"
                        >
                            {renderAndGroupingSelectOptions(lastDeliveryByFuelTruck)}
                        </Select>
                    </div>
                    <div key={"2133131"} className="col-md-4">
                        <Button className="mt-2 m-1 delete-filter" type="dashed" onClick={resetFilters} size="middle">
                            Hapus Filter
                        </Button>
                    </div>
                </div>
                <div className="row mb-4">
                    <div key={"213131"} className="col-md-12">
                        {
                            LabelPeriodeDeliveryDate && (
                                <div key={"434223"} className="row mt-4">
                                    <div key={"33333"} className="col-md-12 d-flex justify-content-center">
                                        <label>Periode : {LabelPeriodeDeliveryDate}</label>
                                    </div>
                                </div>
                            )
                        }
                        <div key={"45363546"} className="row mt-4">
                            <div key={"4123333"} className="col-md-10 offset-md-1">
                                {
                                    loadingLoadServer && (
                                        <div className="fs-6 text-center mt-5 mb-3" style={{ color: "#B7B7B7" }}>
                                            <Spin size="large" >
                                            </Spin>
                                            <Alert
                                                message="Mohon tunggu.."
                                                description="Mengambil data dari server."
                                                type="info"
                                            />
                                        </div>
                                    )
                                }
                                {
                                    loadingLoadIDB && (
                                        <div className="fs-6 text-center mb-3" style={{ color: "#B7B7B7" }}>
                                            <Spin size="large" >
                                            </Spin>
                                            <Alert
                                                message="Mohon tunggu.."
                                                description="Mengambil data dari lokal anda."
                                                type="info"
                                            />
                                        </div>
                                    )
                                }
                                {
                                    requests.length > 0 &&
                                    requests.map((data) => {
                                        return (
                                            <Fragment key={data?.id_record}>
                                                <Badge.Ribbon color="red" text={data?.amount_issued + " LT"}>
                                                    <div
                                                        className="list-style p-2 px-3"
                                                        style={{ background: "#B2DAFF" }}
                                                    >
                                                        <div className="title d-flex justify-content-between">
                                                            <div className="fs-7 mb-0">{data?.hull_number}</div>
                                                            <div className="fs-7 mb-0" style={{ fontSize: 13 }}>
                                                                {
                                                                    isOnline ? (
                                                                        <> Sedang Mengirim <Badge style={{ marginLeft: 10 }} status="processing" /></>
                                                                    ) : (
                                                                        <> Belum dikirim ke server <Badge style={{ marginLeft: 10 }} status="error" /></>
                                                                    )
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="title mt-2">
                                                            <p style={{ fontSize: 11 }}>{data?.operator_id || data?.override_id + " ( Override )"}</p>
                                                        </div>
                                                        <div className="hour d-flex justify-content-between">
                                                            <p className="fs-7">
                                                                <span style={{ color: "#1890FF", fontSize: 11, fontWeight: "bold" }}>
                                                                    {data?.fuel_truck}</span> - <span style={{ fontSize: 11 }}> {moment(data?.date).format('YYYY-MM-DD')} {moment(data?.time).format('HH:mm')}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Badge.Ribbon>
                                            </Fragment>
                                        )
                                    })
                                }
                                {
                                    historyRequest.length > 0 &&
                                    historyRequest.map((data, index) => {
                                        // Format the current time
                                        const formattedTime = moment(data?.time).format('HH:mm');

                                        // Check if there is any previous entry with the same properties
                                        const isDuplicate = historyRequest.some((item, idx) =>
                                            idx !== index &&
                                            moment(item?.time).format('HH:mm') === formattedTime &&
                                            item.hull_number === data.hull_number &&
                                            item.fuel_truck === data.fuel_truck &&
                                            moment(item?.date).format('YYYY-MM-DD') === moment(data?.date).format('YYYY-MM-DD') &&
                                            item.operator?.fullname === data.operator?.fullname &&
                                            item.override_id === data.override_id &&
                                            item.status_approval.status === data.status_approval.status
                                        );

                                        return (
                                            <Fragment key={data?.id_record}>
                                                <Badge.Ribbon text={data?.amount_issued + " LT"}>
                                                    <div
                                                        className={`list-style p-2 px-3 ${isDuplicate ? 'duplicate-entry' : ''}`}
                                                        onClick={() => goDetail(data.record_id)}
                                                    >
                                                        <div className="title d-flex justify-content-between">
                                                            <div className="fs-7 mb-0">{data?.hull_number}</div>
                                                            <div className="fs-7 mb-0" style={{ fontSize: 11 }}>
                                                                {data.record_id.substring(15)} -
                                                                <span style={
                                                                    data.status_approval.status !== "Approved" ?
                                                                        { background: "#E0710F", padding: 3, color: "#FFFFFF", borderRadius: 2 } :
                                                                        { background: "#1890FF", padding: 3, color: "#FFFFFF", borderRadius: 2 }
                                                                }>
                                                                    {data.status_approval.status}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="title mt-2">
                                                            <p style={{ color: "#B7B7B7", fontSize: 11 }}>
                                                                {data.operator?.fullname || data.override_id + " ( Override )"}
                                                            </p>
                                                            {isDuplicate && (
                                                                <p style={{ color: "#FF4D4F", fontSize: 10, fontWeight: "bold", position: "absolute", top: -16, left: 0 }}>
                                                                    ada kemungkinan duplikat
                                                                </p>
                                                            )}
                                                        </div>
                                                        <div className="hour d-flex justify-content-between">
                                                            <p className="fs-7">
                                                                <span style={{ color: "#1890FF", fontSize: 11, fontWeight: "bold" }}>
                                                                    {data?.fuel_truck}
                                                                </span> -
                                                                <span style={{ fontSize: 11 }}>
                                                                    {data.created_by} -
                                                                    {moment(data?.date).format('YYYY-MM-DD')} {formattedTime}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Badge.Ribbon>
                                            </Fragment>
                                        );
                                    })
                                }


                                {
                                    historyRequest.length === 0 &&
                                    (
                                        <div className="fs-6 text-center mt-5" style={{ color: "#B7B7B7" }}>
                                            No more data
                                        </div>
                                    )

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default FuelRequestHistory;
