import React, { useEffect, useRef, useState } from 'react';

import { Layout, Collapse, Form, Row, Col, DatePicker, TimePicker, Radio, InputNumber, Input, Descriptions, Timeline, Alert, Comment, Avatar, Affix, Button, Image, Select, AutoComplete, Checkbox, message } from "antd";

import Navbar from '../../../atoms/navbar';

import { loginRequest } from '../../../config/api-config';
import HeaderFarms from '../../../utils/header_farms';
import API from '../../../services';

import { CheckCircleOutlined, CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router';
import { useMsal } from '@azure/msal-react';
import { Block, Confirm, Report } from 'notiflix';
import { QRCodeSVG } from 'qrcode.react';
import moment from 'moment';

function RequestDeliveryFuelAdjustment() {

    const { instance, accounts } = useMsal();
    const operatorInputRef = useRef(null);
    const [form] = Form.useForm();
    let navigate = useNavigate();

    const [statusColor, setStatusColor] = useState({ background: null, color: null })
    const [RequestDeliveryDetail, setRequestDeliveryDetail] = useState(false)
    const [currentOperator, setCurrentOperator] = useState(null)
    const [FT, _] = useState(['TS-9657-MMS', 'FT-002', 'FT-003', 'Tangki A', 'Tangki B'])
    const [fuelLoss, setFuelLoss] = useState(false);
    const [Operators, setOperators] = useState([]);
    const [user, setUser] = useState(null);
    const { RangePicker } = DatePicker;
    const { TextArea } = Input;
    const { Panel } = Collapse;

    let { id } = useParams();

    function RequestToken() {

        const request = {
            ...loginRequest,
            account: accounts[0],
        };

        instance
            .acquireTokenSilent(request)
            .then((responseToken) => {

                const token = responseToken.accessToken;
                getDetailDeliveryRequest(token);
                getOperator(token)
            })
            .catch((e) => {
            });
    }

    async function getUser(token) {
        try {
            let user = await API.getUserCredential(HeaderFarms(token), { access_token: token })
            setUser(user)
        } catch (error) {
            console.error(error)
        }
    }

    const styleStatus = (res) => {
        let status = res.approval_process.status;

        if (status === "Waiting Approval") {
            setStatusColor({
                background: "#FF9900",
                color: "#FFFFFF"
            })
        } else if (status === "Approved") {
            setStatusColor({
                background: "#1890FF",
                color: "#FFFFFF"
            })
        } else {
            setStatusColor({
                background: "#F5222D",
                color: "#FFFFFF"
            })
        }
    }

    const getLastDeliveryFuelTruck = (ft) => {
        API.DeliveryLatest(HeaderFarms(), ft)
            .then(latest => {
                if (latest.data) {
                    const latestDate = moment(latest.data.date); // Ambil tanggal terbaru dari respon API
                    const currentDate = moment(); // Ambil tanggal saat ini

                    form.setFieldsValue({
                        issued_period: [latestDate, currentDate],
                        issued_period_before: latest.data.last_total_amount_issued,
                        remaining_fuel_period: latest.data.remaining_fuel_period
                    });

                    if (latest.data.remaining_fuel_period < 0) {
                        setFuelLoss(true)
                        form.setFieldsValue({
                            fueloss: latest.data.remaining_fuel_period
                        })
                    } else {
                        setFuelLoss(false)
                        form.setFieldsValue({
                            fueloss: latest.data.remaining_fuel_period
                        })
                    }

                } else {
                    form.setFieldsValue({
                        issued_period: [moment(), moment()],
                        issued_period_before: null,
                        remaining_fuel_period: null
                    });
                }
            })
            .catch(err => {
                console.error(err);
                Report.warning(
                    "Oops.. something wrong",
                    "Sorry, Failed load latest delivery",
                    "Okay"
                );
            });
    }

    const fuelLostHandle = (event) => {
        setFuelLoss(event.target.checked);
    }

    const getDetailDeliveryRequest = async (token) => {
        try {
            const res = await API.ViewDeliveryRequestFuel(HeaderFarms(token), id);

            Block.remove('.ant-collapse');

            res.data.date = moment(res.data.date)
            res.data.time = moment(res.data.time)
            res.data.record_id_adjustment = res.data.record_id

            form.setFieldsValue({
                issued_period: [moment(res.data.issued_period_date_from), moment(res.data.issued_period_date_to)],
                ...res.data
            });

            form.setFieldsValue(res.data)

            setRequestDeliveryDetail(res.data);
            setCurrentOperator(res.data.operator_id.split(" - ")[0])
            styleStatus(res.data);
            // // only one call
            user || getUser(token)

        } catch (err) {
            console.error(err);
            Report.info("Working Permit", "Sorry, Fuel Request not found", "Okay");
            Block.remove('.ant-collapse');
        }
    };

    const getOperator = (token) => {
        API.Operators(HeaderFarms(token), null).then(res => {
            setOperators(res.data)
        }).catch(err => {
            console.error(err)
            Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
            );
        })
    }

    const submitAdjustment = (data) => {

        Confirm.show(
            'Important Confirmation',
            'Are you sure?',
            'Yes',
            'No',
            () => {
                const request = {
                    ...loginRequest,
                    account: accounts[0],
                };

                instance
                    .acquireTokenSilent(request)
                    .then((responseToken) => {

                        data.operator_id = currentOperator.employee_id ||
                            data.operator_id.split(" - ")[0]

                        // Send FormData to server
                        API.AdjustmentDeliveryRequestFuel(HeaderFarms(responseToken.accessToken), data)
                            .then((_) => {
                                Report.success(
                                    `Has been updated.`,
                                    "Berhasil di sesuaikan",
                                    "Okay",
                                    function () {
                                        navigate("/fms/menu")
                                    }
                                );
                            })
                            .catch((err) => {
                                console.error(err)
                                Report.warning(
                                    "Oops.. something wrong",
                                    "Sorry, this application is experiencing problems",
                                    "Okay"
                                );
                            })
                    })
                    .catch((e) => {
                        // Handle error
                    });
            }
        );
    }

    const onFinishFailed = () => {
        message.warning('Mohon lengkapi formulir.')
    }

    const handleAmountQuantity = (value) => {

        let remaining_fuel_period = parseInt(
            form.getFieldsValue()['remaining_fuel_period']
        )

        if (parseInt(form.getFieldsValue()['remaining_fuel_period']) < 0) {
            remaining_fuel_period = 0
        }

        form.setFieldsValue({
            flowmeter_total: value,
            issued_total:
                form.getFieldsValue()['quantity_request'] + remaining_fuel_period
        })

    };

    const handleChangeOperator = (data) => {
        // Set operator_id to null and blur operator input
        // form.setFieldsValue({ operator_id: null });
        // operatorInputRef.current.blur();

        // Find the current operator
        const currentOperator = Operators.find(operator => operator.employee_id === data);
        setCurrentOperator(currentOperator);

        // Show the modal and focus on pin input after a short delay
        // showModal(true);
        // setTimeout(() => pinInputRef.current.focus(), 100);
    };

    useEffect(() => {

        if (accounts.length > 0) {
            RequestToken()
        }

    }, []);

    return (
        <>
            <Navbar
                title="Create"
                back={true}
                subtitle="Fuel System"
                navigate="/fms/menu"
            ></Navbar>
            <div className="container-fluid white-back-cr">
                <Form
                    form={form}
                    name="fuel_delivery"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={submitAdjustment}
                    encType='multipart/form-data'
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size='middle'
                    layout='vertical'
                >
                    <div className="container-fluid p-2" style={{ alignSelf: "center" }}>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <Collapse defaultActiveKey={['1']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Approval Process</b>} key="1">
                                        {RequestDeliveryDetail ? (
                                            <>
                                                <div className="row mt-3">
                                                    <div className="col-md-12">
                                                        {
                                                            RequestDeliveryDetail && (
                                                                <Descriptions size="small" bordered title="Information" className="mb-4">
                                                                    <Descriptions.Item label="Form ID">
                                                                        {RequestDeliveryDetail.record_id}
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item style={{ background: statusColor.background, color: statusColor.color }} label="Status">
                                                                        {RequestDeliveryDetail.approval_process.status}
                                                                    </Descriptions.Item>
                                                                    <Descriptions.Item label="Created at">
                                                                        {moment(RequestDeliveryDetail.created_at).format('LLL')}
                                                                    </Descriptions.Item>
                                                                </Descriptions>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-5'>
                                                        <label style={{ fontSize: 15, fontWeight: "bold" }}>Approval Status</label>
                                                        <Timeline style={{ marginTop: 30 }}>
                                                            {
                                                                RequestDeliveryDetail.approval_process.detail.map(item => {
                                                                    return (
                                                                        <Timeline.Item key={item._id}>{item.deputy_approver ? `Deputy (${item.deputy_approver})` : item.approved_by.fullname}
                                                                            <Alert
                                                                                message={item.status ? `Approved - ${moment(item.approved_at).format('YYYY-MM-DD HH:mm')}` : `Waiting Approval`}
                                                                                className='mt-1 p-1'
                                                                                type={RequestDeliveryDetail.approval_process.detail.find(a => a.status === false) ? 'warning' : 'success'}
                                                                                showIcon
                                                                            />
                                                                        </Timeline.Item>
                                                                    )
                                                                })
                                                            }
                                                        </Timeline>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </Panel>
                                </Collapse>
                            </div>
                            {RequestDeliveryDetail && RequestDeliveryDetail.approval_process.approval_key && (
                                <>
                                    <div className="col-md-12">
                                        <Collapse defaultActiveKey={['1']}>
                                            <Panel header="Approved Barcode" key="1">
                                                <div className="row">
                                                    <div className="col-md-12 text-center">
                                                        <QRCodeSVG size={170} style={{ border: 1, borderRadius: 2, borderStyle: "solid", padding: 10 }} value={`${window.location.origin}/wf/approved/${RequestDeliveryDetail.approval_process.approval_key}`} />
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </>

                            )}
                            <div className="col-md-12">
                                {
                                    RequestDeliveryDetail && RequestDeliveryDetail.approval_process.comments.length > 0 && (
                                        <Collapse defaultActiveKey={['1']}>
                                            <Panel header="Comments" key="1">
                                                {
                                                    RequestDeliveryDetail && RequestDeliveryDetail.comments.map(data => {
                                                        return (
                                                            <Comment
                                                                key={data._id}
                                                                author={<p>{data.uid.fullname}</p>}
                                                                avatar={<Avatar src="https://api.dicebear.com/7.x/identicon/svg?seed=Bear" alt="Han Solo" />}
                                                                content={
                                                                    <p>
                                                                        {data.text_plain}
                                                                    </p>
                                                                }
                                                            />
                                                        )
                                                    })
                                                }
                                            </Panel>
                                        </Collapse>
                                    )
                                }
                            </div>
                            <div className='col-md-12'>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} accordion={false} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Informasi Penyerahan</b>} key="2">
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Date"
                                                    name="date"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input date',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker style={{ pointerEvents: "none" }} showTime format="YYYY-MM-DD" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                                                <Form.Item
                                                    label="Time"
                                                    name="time"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input time',
                                                        },
                                                    ]}
                                                >
                                                    <TimePicker style={{ pointerEvents: "none" }} showNow={false} format="HH:mm" />
                                                </Form.Item>
                                            </Col>
                                            <Form.Item
                                                style={{ display: 'none' }}
                                                label=""
                                                name="record_id_adjustment"
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: 'Wajib Isi',
                                                    },
                                                ]}
                                            >
                                                <Input hidden />
                                            </Form.Item>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Fuel Truck"
                                                    name="fuel_truck"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <AutoComplete
                                                        placeholder="Select"
                                                        allowClear
                                                        onChange={getLastDeliveryFuelTruck}
                                                        options={FT.map((item) => ({ value: item }))}
                                                        filterOption={(inputValue, option) => {
                                                            return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Tangki Penyimpanan"
                                                    name="location_storage"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <AutoComplete
                                                        placeholder="Select"
                                                        allowClear
                                                        options={
                                                            ['T-201-A', 'T-201-B', 'T-20-KL', 'T-16-KL', 'T-8-KL']
                                                                .map((item) => ({ value: item }))}
                                                        filterOption={(inputValue, option) => {
                                                            return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 11 }} xl={{ span: 11 }}>
                                                <Form.Item
                                                    label="Nama Supir / Operator"
                                                    name="operator_id"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        placeholder="Select"
                                                        allowClear
                                                        showSearch
                                                        optionFilterProp="children"
                                                        onChange={handleChangeOperator}
                                                        ref={operatorInputRef}
                                                        clearIcon={false}
                                                        suffixIcon={form.getFieldsValue()['operator_id'] ? <CheckCircleOutlined style={{ color: 'green', fontSize: 18 }} /> : <CloseCircleOutlined style={{ color: 'red', fontSize: 18 }} />}
                                                    >
                                                        {Operators.map((item) => (
                                                            <Select.Option key={item.employee_id} value={item.employee_id}>
                                                                {item.fullname} - {item.employee_id}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Jenis Bahan Bakar"
                                                    name="fuel_type_delivery"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Radio.Group defaultValue="Bio Solar B35">
                                                        <Radio value="Bio Solar B35">Bio Solar B35</Radio>
                                                        <Radio value="Benzine">Bensin</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Quantity yang diminta"
                                                    name="quantity_request"

                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber onChange={handleAmountQuantity} formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')} addonAfter="L" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                                <Collapse className='collapse_sr' expandIconPosition={"end"} accordion={false} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                    <Panel style={{ background: "#fce8b6" }} header={<b>Pemeriksaan</b>} key="2">
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Kondisi Tangki"
                                                    name="tank_kondition"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <Radio.Group defaultValue={'Filled'}>
                                                        <Radio value="Blank">Kosong</Radio>
                                                        <Radio value="Filled">Terisi</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 12 }} xl={{ span: 12 }}>
                                                <Form.Item
                                                    label="Periode Penyaluran"
                                                    name="issued_period"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input date',
                                                        },
                                                    ]}
                                                    initialValue={moment()}
                                                >
                                                    <RangePicker format="YYYY-MM-DD HH:mm" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Penyaluran Sebelumnya"
                                                    name="issued_period_before"

                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')} addonAfter="L" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 10 }} xl={{ span: 10 }}>
                                                <Form.Item
                                                    label="Sisa Minyak Dalam Tangki"
                                                    name="remaining_fuel_period"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber type={"number"} step={0.1} addonAfter="L"
                                                        addonBefore={<><Checkbox onChange={fuelLostHandle} checked={fuelLoss} style={{ fontSize: '16px', fontWeight: 'bold', color: 'red' }}>
                                                            Fuel Loss
                                                        </Checkbox></>} />
                                                </Form.Item>
                                            </Col>
                                            {
                                                fuelLoss && (
                                                    <>
                                                        <Col xs={{ span: 24 }} sm={{ span: 6 }} xl={{ span: 6 }}>
                                                            <Form.Item
                                                                label={<span style={{ fontSize: '16px', fontWeight: 'bold' }}>Description Fueloss</span>}
                                                                name="fueloss_description"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <TextArea rows={2} />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label="Total Fueloss"
                                                                name="fueloss"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Wajib Isi',
                                                                    },
                                                                ]}
                                                            >
                                                                <InputNumber type={"number"} step={0.1} addonAfter="L" />
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )
                                            }
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Sounding Awal"
                                                    name="sounding_before"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber type={"number"} step={0.1} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 9 }} xl={{ span: 9 }}>
                                                <Form.Item
                                                    label="Sounding Akhir"
                                                    name="sounding_after"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber type={"number"} step={0.1} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                                                <Form.Item
                                                    label="Angka Flowmeter"
                                                    name="flowmeter_total"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')} addonAfter="L" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                                                <Form.Item
                                                    label="Total Setelah Pengisian"
                                                    name="issued_total"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi',
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')} addonAfter="L" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={20}>
                                            <Col xs={{ span: 24 }} sm={{ span: 16 }} xl={{ span: 16 }}>
                                                <Form.Item
                                                    label="Catatan Penyesuaian"
                                                    name="adjustment_note"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Wajib Isi dengan minimal 20 karakter',
                                                            min: 20,
                                                        },
                                                    ]}
                                                >
                                                    <Input.TextArea rows={2} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            {
                                RequestDeliveryDetail && (
                                    <Collapse className='collapse_sr' expandIconPosition={"end"} accordion={false} bordered={true} defaultActiveKey={['0', '1', '2', '3', '4', '5']}>
                                        <Panel style={{ background: "#fce8b6" }} header={<b>Attachment Flowmeter</b>} key="2">
                                            <div className="row">
                                                <div className="col">
                                                    <label style={{ marginBottom: '10px', display: 'block' }}>Flow Meter</label>
                                                    {RequestDeliveryDetail.record_id && (
                                                        <Image
                                                            width={100}
                                                            src={"https://farms-fms-flowmeter.s3.ap-southeast-3.amazonaws.com/" + RequestDeliveryDetail.record_id}
                                                            fallback={"https://via.placeholder.com/201"}
                                                        />
                                                    )}
                                                </div>
                                                <div className="col">
                                                    <label style={{ marginBottom: '10px', display: 'block' }}>Operator</label>
                                                    {RequestDeliveryDetail.record_id && (
                                                        <Image
                                                            width={100}
                                                            src={"https://farms-fms-operator.s3.ap-southeast-3.amazonaws.com/" + RequestDeliveryDetail.record_id}
                                                            fallback={"https://via.placeholder.com/200"}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                )
                            }

                        </div>
                    </div>
                    <div className="row mt-4">
                        <Row gutter={[24, 24]}>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                                <Affix offsetBottom={10}>
                                    <Form.Item className="text-center">
                                        <Button
                                            type="primary"
                                            className="m-3"
                                            size="middle"
                                            htmlType="submit"
                                            icon={<CheckOutlined />}
                                        >
                                            Save Adjustment
                                        </Button>
                                    </Form.Item>
                                </Affix>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <Layout.Footer style={{ textAlign: 'center', background: "#F9F9F9" }}>©2024 Information Technology</Layout.Footer>
        </>
    );
}

export default RequestDeliveryFuelAdjustment